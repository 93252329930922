import { FaVolumeUp } from 'react-icons/fa'
import { FaVolumeMute } from 'react-icons/fa'
import { usePlayer, usePlayerState } from '../Media/Media'

export default function MuteButton (props) {
  const { active, chalkboard, quizSuccess, tf } = props
  const player = usePlayer()
  const { muted } = usePlayerState()
  const toggleMute = () => player.dispatch({ type: 'MUTED', muted: !muted })

  return (
    <button
      className={`interactive-mute ${quizSuccess ? quizSuccess : ''} ${
        tf ? 'tf' : ''
      } ${chalkboard ? 'chalkboard' : ''}`}
      aria-label={muted === true ? 'unmute' : 'mute'}
      onClick={toggleMute}
      //  onKeyDown={handleArrowKeyDowns}
      tabIndex={active ? 0 : -1}
    >
      {muted === true ? (
        <FaVolumeMute alt='' aria-hidden='' />
      ) : (
        <FaVolumeUp alt='' aria-hidden='true' />
      )}
    </button>
  )
}
