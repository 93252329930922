import React, { useState, useEffect, useRef } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { MediaPlayer } from '@moderati/mod-components'
import { createHtmlPortalNode, InPortal, OutPortal } from 'react-reverse-portal'
import useBreakpoint from 'use-breakpoint'
import useMeasure from 'react-use-measure'
import screenfull from 'screenfull'
import {
  handleGetChapters,
  handleGetTrackList,
  handlePlayPause,
  handleSkip,
  showUi,
  hideUi,
  adjustCueLine
} from '../Controls/handlerFunctions'
import { playbackProgress, setTimelineEvents } from './handleTimelineEvents'

// Components
import CaptionsMenu from '../Controls/CaptionsMenu'
import ChapterIntroScreen from '../ChapterIntroScreen.js/ChapterIntroScreen'
import Controls from '../Controls/Controls'
import Chalkboard from '../Chalkboard'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import Quiz from '../Quiz/Quiz'
import QuizList from '../Quiz/QuizList'
import TableOfContents from '../TableOfContents/TableOfContents'
import Transcripts from '../Transcripts/Transcripts'
import TranscriptAccordion from '../TranscriptAccordion/TranscriptAccordion'
import PauseTrigger from './PauseTrigger'

import { usePlayer, usePlayerState } from '../Media/Media'

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }
// Files
const image = 'https://cdn.aetnamedicarebasics.com/videos/barb-poster.png'

export default function Video (props) {
  const {
    currentCaptions,
    setCurrentCaptions,
    isCaptionsMenuOpen,
    setIsCaptionsMenuOpen,
    trackList,
    playerView,
    setPlayerView,
    src,
    timelineEventsJson,
    tracks,
    setTrackList
  } = props

  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'mobile')

  const player = usePlayer()

  const { ref: childRef } = player

  const [currentChapter, setCurrentChapter] = useState(null)
  const [expandedControls, setExpandedControls] = useState(false)

  const {
    buffering,
    playing,
    volume,
    muted,
    timelineEvents,
    persistentEvents,
    uiVisible,
    controlsHovered,
    chapterList
  } = usePlayerState()

  const videoHover = controlsHovered || uiVisible

  const setVideoHover = () => {}

  const [captionFiles, setCaptionFiles] = useState([
    { language: 'en', name: 'English' },
    {
      language: 'es',
      name: 'Español'
    }
  ])
  const [cues, setCues] = useState(null)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [isTableOpen, setIsTableOpen] = useState(false)
  const [isTranscriptOpen, setIsTranscriptOpen] = useState(false)

  const [quizSuccess, setQuizSuccess] = useState(null)
  const [reverseCcBlur, setReverseCcBlur] = useState(false)
  const [videoTimelineEvents, setVideoTimelineEvents] = useState(
    timelineEventsJson
  )

  /// Refs
  const ccButtonRef = useRef(null)
  const firstCcRef = useRef(null)
  const lastCcRef = useRef(null)
  const playerContainerRef = useRef(null)
  const quizFocusRef = useRef({})

  const [boundsRef, bounds] = useMeasure()

  useEffect(() => {
    if (trackList) {
      const chapters = handleGetChapters(player, trackList)

      const chapterEvents = chapters.reduce((acc, value, index) => {
        if (value.startTime === 0) return acc
        return [
          ...acc,
          {
            start: value.startTime - 7,
            end: value.startTime - 1,
            type: 'chapter',
            id: 'ch' + index,
            props: {
              duration: 60,
              startTime: value.startTime,
              endTime: value.endTime,
              text: value.text
            }
          },
          {
            id: 'p' + index,
            start: value.startTime - 2,
            type: 'pause',
            props: { duration: 55 }
          }
        ]
      }, [])

      setVideoTimelineEvents(
        [...timelineEventsJson, ...chapterEvents].map((event, id) => ({
          ...event,
          id: event.id || id
        }))
      )
    }
  }, [trackList])

  useEffect(() => {
    if (videoHover === true) {
      adjustCueLine(-5, trackList)
    }
    if (videoHover === false || playerView === 'expanded') {
      adjustCueLine(-2, trackList)
    }
  }, [currentCaptions, videoHover, trackList, playerView])

  useEffect(() => {
    if (screenfull && screenfull.on) {
      screenfull.on('change', () => {
        screenfull.isFullscreen ? setIsFullscreen(true) : setIsFullscreen(false)
      })
    }
  }, [src, tracks])

  screenfull && screenfull.on && screenfull.on('change', () => {
    screenfull.isFullscreen ? setIsFullscreen(true) : setIsFullscreen(false)
  })

  const onSkipBack = () => handleSkip(player, -1)
  const onSkipForward = () => handleSkip(player, 1)

  const handleMouseEnter = () => {
    showUi(player)
  }

  const handleMouseMove = () => {
    showUi(player)
  }

  const handleMouseLeave = () => {
    hideUi(player)
  }

  const handleClick = click => {
    showUi(player)
    handlePlayPause(player)
  }

  const handleKeyDown = event => {
    showUi(player)
    // console.log(`event.key`, event.key)
    if (event.keyCode === 32) handlePlayPause(player)
    switch (event.key) {
      case 'ArrowLeft':
        onSkipBack()
        break
      case 'ArrowRight':
        onSkipForward()
        break
      case 'F':
      case 'f':
        toggleFullscreen()
        break
      case 'M':
      case 'm':
        player.dispatch({ type: 'MUTED', muted: !muted })
        break
      case 'T':
      case 't':
        if (playerView === 'default') setPlayerView('expanded')
        else setPlayerView('default')
        break
      default:
        break
    }
  }

  const handleBuffer = buffering => {
    player.dispatch({ type: 'BUFFERING' })
  }

  const handleBufferEnd = () => {
    player.dispatch({ type: 'CLEAR_BUFFERING' })
  }

  const handleDuration = duration => {
    player.dispatch({ type: 'DURATION', duration })
  }

  const handleQuizClose = React.useCallback(state => {
    player.dispatch({ type: 'CLOSE_QUIZ', quiz: events.quiz })
  }, [])

  const handleProgress = changeState => {
    playbackProgress(player, { changeState })
    setTimelineEvents(player, { videoTimelineEvents })
  }

  const toggleFullscreen = () => {
    screenfull && screenfull.toggle && screenfull.toggle(playerContainerRef.current)
  }

  const handleReady = onReady => {
    !trackList && handleGetTrackList(setTrackList, setCurrentCaptions)
  }

  const portalNode = React.useMemo(() => createHtmlPortalNode(), [])

  const events = {}
  timelineEvents.forEach(event => {
    events[event.type] = event
  })

  const pEvents = {}
  persistentEvents.forEach(event => {
    pEvents[event.type] = event
  })

  // REFACTOR ZONE

  const currentQuestion = events.quiz && events.quiz.props
  const isQuizOpen = !!currentQuestion
  const chapterIntro = pEvents.chapter
  const chalkboard = events.chalkboard
  const pauseTrigger = events.pause

  const renderControls = () => {
    return (
      <Controls
        breakpoint={breakpoint}
        ccButtonRef={ccButtonRef}
        lastCcRef={lastCcRef}
        chapterList={chapterList}
        captionFiles={captionFiles}
        currentChapter={currentChapter}
        setCurrentChapter={setCurrentChapter}
        expandedControls={expandedControls}
        setExpandedControls={setExpandedControls}
        firstCcRef={firstCcRef}
        isCaptionsMenuOpen={isCaptionsMenuOpen}
        setIsCaptionsMenuOpen={setIsCaptionsMenuOpen}
        isFullscreen={isFullscreen}
        setIsFullscreen={setIsFullscreen}
        playerContainerRef={playerContainerRef}
        playerView={playerView}
        setPlayerView={setPlayerView}
        OnFullscreenToggle={toggleFullscreen}
        videoHover={videoHover || events.progressBar}
        setVideoHover={setVideoHover}
        reverseCcBlur={reverseCcBlur}
        setReverseCcBlur={setReverseCcBlur}
        currentCaptions={currentCaptions}
        setCurrentCaptions={setCurrentCaptions}
        showUi={showUi}
        trackList={trackList}
        timelineDemo={events.progressBar}
      />
    )
  }

  const renderTableOfContents = () => {
    return (
      <TableOfContents
        chapterList={chapterList}
        childRef={childRef}
        currentChapter={currentChapter}
        setCurrentChapter={setCurrentChapter}
        isTableOpen={
          isTableOpen || !!events.tableOfContents || playerView === 'expanded'
        }
        setIsTableOpen={setIsTableOpen}
        playerView={playerView}
        videoHover={videoHover}
        chapterSelectDemo={events.tableOfContents}
      />
    )
  }

  return (
    <>
      <InPortal node={portalNode}>
        <div
          id='aie-video-player'
          ref={boundsRef}
          style={{
            fontSize: bounds.width / 10,
            position: 'relative',
            width: '100%',
            height: '100%',
            background: 'black'
          }}
          onMouseMove={handleMouseMove}
          onMouseOut={handleMouseLeave}
          onKeyDown={handleKeyDown}
        >
          <MediaPlayer
            controls={false}
            crossOrigin='anonymous'
            playerRef={childRef}
            src={src}
            autoPlay={false}
            width={1920}
            height={1080}
            muted={muted}
            onDuration={handleDuration}
            progressInterval={100}
            onBuffer={handleBuffer}
            onBufferEnd={handleBufferEnd}
            onProgress={handleProgress}
            onReady={handleReady}
            poster={image}
            playbackRate={1}
            player='react-player'
            preload='metadata'
            tracks={tracks}
            volume={volume}
            playing={playing}
          >
            {buffering && <LoadingSpinner />}
            {playerView !== 'expanded' ? renderTableOfContents() : ''}

            {currentQuestion && currentQuestion.type === 'list' ? (
              <QuizList
                currentQuestion={currentQuestion}
                isQuizOpen={isQuizOpen}
                onClose={handleQuizClose}
                quizFocusRef={quizFocusRef}
                quizSuccess={quizSuccess}
                setQuizSuccess={setQuizSuccess}
              />
            ) : (
              <Quiz
                currentQuestion={currentQuestion}
                isQuizOpen={isQuizOpen}
                onClose={handleQuizClose}
                quizFocusRef={quizFocusRef}
                quizSuccess={quizSuccess}
                setQuizSuccess={setQuizSuccess}
              />
            )}
            <PauseTrigger pause={pauseTrigger} />

            {!isQuizOpen && (
              <div
                style={{ width: '100%', height: '100%' }}
                onClick={handleClick}
              />
            )}

            <ChapterIntroScreen chapterIntro={chapterIntro} />
            <Chalkboard chalkboard={chalkboard} />

            <CaptionsMenu
              captionFiles={captionFiles}
              setCaptionFiles={setCaptionFiles}
              ccButtonRef={ccButtonRef}
              isCaptionsMenuOpen={isCaptionsMenuOpen}
              setIsCaptionsMenuOpen={setIsCaptionsMenuOpen}
              currentCaptions={currentCaptions}
              setCurrentCaptions={setCurrentCaptions}
              firstCcRef={firstCcRef}
              lastCcRef={lastCcRef}
              reverseCcBlur={reverseCcBlur}
              setReverseCcBlur={setReverseCcBlur}
              trackList={trackList}
            />

            {playerView !== 'expanded' &&
              !isQuizOpen &&
              !chalkboard &&
              renderControls()}
          </MediaPlayer>
        </div>
      </InPortal>

      <div
        ref={playerContainerRef}
        className='full-player-container'
        style={{
          overflow: 'hidden',
          maxWidth: '100vw',
          height: 'auto',
          position: 'relative'
        }}
      >
        {playerView === 'expanded' ? (
          <Row>
            <Col xs={12} xl={3}>
              {renderTableOfContents()}
            </Col>
            <Col xs={12} xl={6}>
              <OutPortal node={portalNode} />
              {!isQuizOpen && !chalkboard && renderControls()}
            </Col>
            <Col xs={12} xl={3}>
              <Transcripts
                captionFiles={captionFiles}
                setCaptionFiles={setCaptionFiles}
                cues={cues}
                setCues={setCues}
                childRef={childRef}
                currentCaptions={currentCaptions}
                isTranscriptOpen={isTranscriptOpen}
                setIsTranscriptOpen={setIsTranscriptOpen}
              />
            </Col>
          </Row>
        ) : (
          <>
            <OutPortal node={portalNode} />
            <TranscriptAccordion
              cues={cues}
              setCues={setCues}
              childRef={childRef}
              currentCaptions={currentCaptions}
              isTranscriptOpen={isTranscriptOpen}
              setIsTranscriptOpen={setIsTranscriptOpen}
              captionFiles={captionFiles}
              setCaptionFiles={setCaptionFiles}
            />
          </>
        )}
      </div>
    </>
  )
}
