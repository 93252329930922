import { memo } from 'react'
import { usePauseEffect, useTimeoutTimelineEvent } from '../Media/Media'

const PauseTrigger = props => {
  const {
    pause
  } = props

  useTimeoutTimelineEvent(pause)
  usePauseEffect(pause)

  return false
}

export default memo(PauseTrigger)