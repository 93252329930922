import React, { useEffect, useState, useRef } from 'react'
import useMeasure from 'react-use-measure'
import AudioTrack from './AudioTrack'
import Confetti from './Confetti'
import PieTimer from '../PieTimer/PieTimer'

import { usePlayer } from '../Media/Media'
import MuteButton from '../Controls/MuteButton'

const Quiz = props => {
  const {
    currentQuestion,
    isQuizOpen,
    onClose,
    timeoutDuration = 50000,
    quizFocusRef,
    quizTitle = 'Quiz #1',
    quizSuccess,
    setQuizSuccess,
    questions
  } = props

  const player = usePlayer()

  const [ref, bounds] = useMeasure()

  const quizRef = quizFocusRef.current
  const quizTimeout = useRef(null)

  const resetTimer = () => {
    if (quizTimeout.current) clearTimeout(quizTimeout.current)
  }

  const setTimer = func => {
    resetTimer()
    quizTimeout.current = setTimeout(func, timeoutDuration)
  }

  useEffect(() => {
    if (isQuizOpen) {
      if (quizSuccess === 'question' && quizRef.question) {
        setTimeout(() => {
          quizRef['question'].focus()
        }, 100)
      }

      if (quizSuccess === 'success' && quizRef.success) {
        setTimeout(() => {
          quizRef['success'].focus()
        }, 100)
      }
      if (quizSuccess === 'fail' && quizRef.fail) {
        setTimeout(() => {
          quizRef['fail'].focus()
        }, 100)
      }
      if (quizSuccess === 'timedOut' && quizRef.timedOut) {
        setTimeout(() => {
          quizRef['timedOut'].focus()
        }, 100)
      }
    }
  }, [quizSuccess, isQuizOpen])

  useEffect(() => {
    if (isQuizOpen && currentQuestion) {
      player.dispatch({ type: 'STOP' })

      resetTimer()
      setTimer(() => {
        setQuizSuccess('timedOut')
        setTimer(handleClose)
      })
    } else {
      resetQuiz()
    }
  }, [isQuizOpen, currentQuestion])

  const resetQuiz = () => {
    resetTimer()
    setQuizSuccess('question')
  }

  const handleOption = (option, letter) => {
    setTimer(handleClose)
    if (currentQuestion.type === 'tf') {
      if (currentQuestion.answer === option) {
        setQuizSuccess('success')
      } else {
        setQuizSuccess('fail')
      }
      return
    }

    if (currentQuestion.answer === letter) {
      setQuizSuccess('success')
    } else {
      setQuizSuccess('fail')
    }
  }

  const handleClose = () => {
    onClose()
  }

  const renderOptions = options =>
    options.map((option, index) => {
      const letter = String.fromCharCode(97 + index).toUpperCase()
      return (
        <li
          className={
            isQuizOpen
              ? currentQuestion.type === 'tf'
                ? 'fade-from-bottom'
                : 'fade-from-left'
              : ''
          }
          key={option}
        >
          <button
            aria-label={option}
            aria-describedby='questionText'
            onClick={() => handleOption(option, letter)}
            tabIndex={
              quizSuccess === 'question' && isQuizOpen === true ? '0' : '-1'
            }
          >
            {currentQuestion.type !== 'tf' ? (
              <span className='letter'>{letter}</span>
            ) : (
              ''
            )}
            <span dangerouslySetInnerHTML={{ __html: option }} />
          </button>
        </li>
      )
    })

  const renderProgressDots = dots =>
    dots.map((dot, index) => {
      return (
        <div
          // id={`dot-number` + index}
          className={`progress-dot${
            currentQuestion.question === dot.question ? ' active' : ''
          }`}
          key={dot.question}
        >
          {currentQuestion.question === dot.question ? (
            <span className='sr-only'>
              Question number {index + 1} out of {questions.length}
            </span>
          ) : (
            ''
          )}
        </div>
      )
    })

  const renderMultipleChoice = () => {
    return (
      <div
        ref={el =>
          quizSuccess === 'question'
            ? (quizFocusRef.current['question'] = el)
            : null
        }
        tabIndex={quizSuccess === 'question' && isQuizOpen === true ? 0 : -1}
        id='question'
        aria-hidden={
          quizSuccess === 'question' && isQuizOpen === true ? 'false' : 'true'
        }
        className={`mc quiz-question${
          quizSuccess === 'question' ? ' active' : ''
        }`}
        role='dialog'
        aria-labelledby='currentQuestion'
      >
        <div
          key='mc-container'
          className={`question-copy ${isQuizOpen ? ' slide-left' : ''}`}
          id='currentQuestion'
        >
          <div
            className={`question-wrapper ${
              isQuizOpen ? 'slide-left-inner' : ''
            }`}
          >
            <h2 id='questionTitle' className='quiz'>
              {currentQuestion ? currentQuestion.title : quizTitle}
            </h2>
            {quizSuccess === 'question' && isQuizOpen === true && (
              <h3 id='questionText'>
                {currentQuestion ? currentQuestion.question : ''}
              </h3>
            )}
          </div>
        </div>

        <div className='answer-copy'>
          <PieTimer
            size='3em'
            color='white'
            seconds={currentQuestion ? timeoutDuration / 1000 : 0}
            style={{ position: 'absolute', right: '2rem', top: '2rem' }}
          />
          <h4 className='quiz fade-from-left'>Select answer below</h4>
          <ol type='A'>
            {currentQuestion ? renderOptions(currentQuestion.options) : ''}
          </ol>
        </div>
      </div>
    )
  }

  const renderTrueFalse = () => {
    if (!currentQuestion.options) currentQuestion.options = [true, false]
    return (
      <div
        ref={
          quizSuccess === 'question' && currentQuestion.type === 'tf'
            ? el => (quizFocusRef.current['question'] = el)
            : null
        }
        id='question'
        aria-hidden={
          quizSuccess === 'question' && isQuizOpen === true ? 'false' : 'true'
        }
        className={`quiz-question${
          quizSuccess === 'question' ? ' active' : ''
        }`}
        role='dialog'
        aria-describedby='currentQuestion'
        tabIndex={
          quizSuccess === true && currentQuestion.type && isQuizOpen === true
            ? currentQuestion.type === 'tf'
              ? 0
              : -1
            : -1
        }
      >
        <div
          ref={el => (quizRef['success'] = el)}
          className='question-copy true-false'
          id='currentQuestion'
        >
          <div
            className={`upper-copy ${
              isQuizOpen && quizSuccess === 'question' ? 'slide-from-top' : ''
            }`}
          >
            <PieTimer
              size='3em'
              color='rgba(125, 63, 152, 1)'
              seconds={currentQuestion ? timeoutDuration / 1000 : 0}
              style={{ position: 'absolute', right: '2rem', top: '2rem' }}
            />
            <div className='content-container slide-top-inner'>
              <h2 id='questionTitle' className='quiz'>
                {currentQuestion.title || quizTitle}
              </h2>
              {questions ? (
                questions.length > 1 ? (
                  <div className='dot-container'>
                    {renderProgressDots(questions)}
                  </div>
                ) : (
                  ''
                )
              ) : (
                ''
              )}

              <h3 id='currentQuestion'>
                {currentQuestion ? currentQuestion.question : ''}
              </h3>
            </div>
          </div>

          <div
            className={`triangle-divider ${
              isQuizOpen && quizSuccess === 'question'
                ? ' triangle-slide-from-top'
                : ''
            }`}
          />

          <div className={`lower-copy `}>
            <div className='content-container'>
              <ol type='A'>{renderOptions(currentQuestion.options)}</ol>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderAudioTracks = () => {
    if (!isQuizOpen) return false
    if (!(currentQuestion && currentQuestion.audio)) return false

    const sources = {
      question: currentQuestion.audio.prompt,
      success: currentQuestion.audio.correct,
      fail: currentQuestion.audio.incorrect,
      timedOut: currentQuestion.audio.timeout
    }

    const voSource = sources[quizSuccess]

    return (
      <>
        {voSource && <AudioTrack key={voSource} src={voSource} />}
        <AudioTrack
          key={currentQuestion.audio.bg}
          src={currentQuestion.audio.bg}
          music
          loop
        />
      </>
    )
  }

  return (
    <>
      {renderAudioTracks()}
      <div
        ref={ref}
        key={currentQuestion && currentQuestion.question}
        style={{ fontSize: bounds.width / 100 }}
        className={`quiz-container${isQuizOpen === true ? ' active' : ''}`}
      >
        {currentQuestion && currentQuestion.type === 'tf'
          ? renderTrueFalse()
          : renderMultipleChoice()}

        <div
          ref={el => (quizFocusRef.current['container'] = el)}
          id='success'
          aria-hidden={
            quizSuccess === 'success' && isQuizOpen === true ? 'false' : 'true'
          }
          aria-describedby='quizSuccess'
          className={`quiz-success ${
            quizSuccess === 'success' ? ' active' : ''
          }`}
          role='dialog'
        >
          <Confetti run={quizSuccess === 'success'} />
          <div
            className={`result-copy ${
              quizSuccess === 'success' || !isQuizOpen ? 'fade-from-bottom' : ''
            }`}
          >
            <PieTimer
              size='3em'
              color='white'
              seconds={quizSuccess === 'success' ? timeoutDuration / 1000 : 0}
              style={{ position: 'absolute', right: '2rem', top: '2rem' }}
            />
            <span id='quizSuccess'>
              {quizSuccess === 'success' && isQuizOpen === true && (
                <h2
                  aria-hidden={
                    quizSuccess === 'success' && isQuizOpen === true
                      ? 'false'
                      : 'true'
                  }
                >
                  {currentQuestion ? currentQuestion.response.correct[0] : ''}
                </h2>
              )}
              <p
                dangerouslySetInnerHTML={{
                  __html: currentQuestion
                    ? currentQuestion.response.correct[1]
                    : ''
                }}
              />
            </span>

            <button
              ref={el => (quizFocusRef.current['success'] = el)}
              className='quiz-btn'
              onClick={handleClose}
              tabIndex={
                quizSuccess === 'success' && isQuizOpen === true ? '0' : '-1'
              }
            >
              Continue
            </button>
          </div>
        </div>

        <div
          ref={el => (quizFocusRef.current['container'] = el)}
          id='failure'
          aria-hidden={
            quizSuccess === 'fail' && isQuizOpen === true ? 'false' : 'true'
          }
          aria-describedby='quizFail'
          className={`quiz-failure${quizSuccess === 'fail' ? ' active' : ''}`}
        >
          <div
            className={`result-copy ${
              quizSuccess === 'fail' || !isQuizOpen ? 'fade-from-bottom' : ''
            }`}
          >
            <PieTimer
              size='3em'
              color='white'
              seconds={quizSuccess === 'fail' ? timeoutDuration / 1000 : 0}
              style={{ position: 'absolute', right: '2rem', top: '2rem' }}
            />
            {quizSuccess === 'fail' && isQuizOpen === true && (
              <span id='quizFail'>
                <h2>
                  {currentQuestion ? currentQuestion.response.incorrect[0] : ''}
                </h2>

                <p
                  dangerouslySetInnerHTML={{
                    __html: currentQuestion
                      ? currentQuestion.response.incorrect[1]
                      : ''
                  }}
                />
              </span>
            )}

            <button
              ref={el => (quizFocusRef.current['fail'] = el)}
              className='quiz-btn'
              id='fail'
              onClick={handleClose}
              tabIndex={
                quizSuccess === 'fail' && isQuizOpen === true ? '0' : '-1'
              }
            >
              Continue
            </button>
          </div>
        </div>

        <div
          ref={el => (quizFocusRef.current['container'] = el)}
          key={currentQuestion && currentQuestion.response.timeout}
          id='timedOut'
          aria-hidden={
            quizSuccess === 'timedOut' && isQuizOpen === true ? 'false' : 'true'
          }
          aria-describedby='quizFail'
          className={`quiz-failure${
            quizSuccess === 'timedOut' ? ' active' : ''
          }`}
        >
          <div
            className={`result-copy ${quizSuccess === 'timedOut' ||
              !isQuizOpen} ? 'fade-from-bottom' : ''}`}
          >
            <PieTimer
              size='3em'
              color='white'
              seconds={quizSuccess === 'timedOut' ? timeoutDuration / 1000 : 0}
              style={{ position: 'absolute', right: '2rem', top: '2rem' }}
            />
            <span id='quizFail'>
              {quizSuccess === 'timedOut' && isQuizOpen === true && (
                <h2>
                  {currentQuestion ? currentQuestion.response.timeout[0] : ''}
                </h2>
              )}
              <p
                dangerouslySetInnerHTML={{
                  __html: currentQuestion
                    ? currentQuestion.response.timeout[1]
                    : ''
                }}
              />
            </span>

            <button
              ref={el => (quizFocusRef.current['timedOut'] = el)}
              className='quiz-btn'
              id='fail'
              onClick={handleClose}
              tabIndex={
                quizSuccess === 'timedOut' && isQuizOpen === true ? '0' : '-1'
              }
            >
              Continue
            </button>
          </div>
        </div>
        <MuteButton
          active={isQuizOpen ? true : false}
          quizSuccess={quizSuccess}
          tf={
            currentQuestion
              ? currentQuestion.type === 'tf'
                ? true
                : false
              : false
          }
        />
      </div>
    </>
  )
}

export default React.memo(Quiz)
