import { useEffect } from 'react'

const Confetti = ({ run }) => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://cdn.aetnamedicarebasics.com/confetti.js'
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    if (run) {
      window.startConfetti && window.startConfetti()
    } else {
      window.stopConfetti && window.stopConfetti()
    }
  }, [run])

  return (
    <canvas
      id='confetti-canvas'
      style={{
        width: '100%',
        height: '100%',
        zIndex: 99999,
        pointerEvents: 'none'
      }}
    />
  )
}

export default Confetti
