import React, { useState } from 'react'
import Quiz from './Quiz'

export default function QuizList (props) {
  const {
    currentQuestion,
    onClose,
    quizFocusRef,
    quizSuccess,
    setQuizSuccess
  } = props

  const questions = currentQuestion.questions

  const [questionIndex, setQuestionIndex] = useState(0)

  const handleClose = () => {
    setQuestionIndex(index => {
      const newIndex = index + 1
      if (newIndex === questions.length) {
        onClose()

        return
      } else {
        setQuizSuccess('question')
        return newIndex
      }
    })
  }

  return (
    <>
      <Quiz
        {...props}
        onClose={handleClose}
        currentQuestion={questions[questionIndex]}
        questions={questions}
        quizSuccess={quizSuccess}
        setQuizSuccess={setQuizSuccess}
        questionIndex={questionIndex}
        quizFocusRef={quizFocusRef}
      />
    </>
  )
}
