import { memo, useEffect, useRef } from 'react'
import useMeasure from 'react-use-measure'
import {
  usePlayer,
  usePauseEffect,
  useTimeoutTimelineEvent
} from '../Media/Media'
import { handleSeekTo } from '../Controls/handlerFunctions'
import PieTimer from '../PieTimer/PieTimer'

const ChapterIntroScreen = props => {
  const { chapterIntro } = props

  const isChapterIntroOpen = !!chapterIntro

  const player = usePlayer()

  const handleClick = () => {
    handleSeekTo(player, chapterIntro.start + 7)
    player.dispatch({ type: 'CLEAR_TIMELINE_EVENTS' })
    player.dispatch({ type: 'PLAY' })
  }

  const [ref, bounds] = useMeasure()

  const closeButtonRef = useRef(null)

  useEffect(() => {
    if (isChapterIntroOpen) closeButtonRef.current.focus()
  }, [isChapterIntroOpen])
  return (
    <div
      ref={ref}
      style={{ fontSize: bounds.width / 100 }}
      aria-hidden={isChapterIntroOpen === true ? 'false' : 'true'}
      className={`chapter-intro-container${
        isChapterIntroOpen === true ? ' active' : ''
      }`}
    >
      <button
        id='closeChapterIntro'
        ref={closeButtonRef}
        className='btn green-btn'
        onClick={handleClick}
        tabIndex={isChapterIntroOpen === true ? 0 : -1}
      >
        Continue
        <span className='sr-only'>watching the video</span>
        <PieTimer
          size={parseInt(bounds.width / 70)}
          color='black'
          seconds={
            chapterIntro
              ? chapterIntro.props
                ? chapterIntro.props.duration
                : 0
              : 0
          }
          style={{ margin: '0 0 0.15em 0.75em' }}
        />
      </button>
    </div>
  )
}

export default memo(ChapterIntroScreen)
