export const setTimelineEvents = (player, { videoTimelineEvents }) => {
  const { dispatch, state } = player
  const { playedSeconds, previousPlayedSeconds } = state

  const events = videoTimelineEvents.filter(
    event => !event.end && 
      playedSeconds > event.start &&
      previousPlayedSeconds < event.start &&
      playedSeconds - previousPlayedSeconds < 0.5
  )

  const persistentEvents = videoTimelineEvents.filter(
    event => 
      playedSeconds > event.start &&
      playedSeconds < event.end
  )

  if (events.length) {
    dispatch({
      type: 'ADD_TRIGGERED_EVENTS',
      events
    })
  }

  if (persistentEvents.length) {
    dispatch({
      type: 'ADD_PERSISTENT_EVENTS',
      persistentEvents
    })
  } else {
    dispatch({
      type: 'ADD_PERSISTENT_EVENTS',
      persistentEvents: []
    })
  }
}

export const playbackProgress = (player, {changeState}) => {
  const { dispatch } = player
  dispatch({ type: 'PROGRESS', progress: changeState })
}

export const getProgressFromSeconds = (seconds, chapter) => {
  let progress = 0

  if (
    seconds >= chapter.startTime &&
    seconds < chapter.endTime
  ) {
    progress = (
      (seconds - chapter.startTime) /
      (chapter.endTime - chapter.startTime)
    )
  }
  if (seconds >= chapter.endTime) {
    progress = 1
  }

  return progress
}
