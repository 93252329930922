import React, { useState, useEffect } from 'react'

import usePrevious from '../../hooks/usePrevious'
import { usePlayerState } from '../Media/Media'

export default function Transcripts (props) {
  const {
    accordion,
    captionFiles,
    cues,
    setCues,
    setCaptionFiles,
    childRef,
    currentCaptions,
    trackList
  } = props

  const previousCues = usePrevious(cues)
  const previousCaptions = usePrevious(currentCaptions)
  const { ready } = usePlayerState()
  useEffect(() => {
    if (currentCaptions) {
      // workaround, I'm pretty sure that the currentCaptions.cues is set mutably
      // (i.e. currentCaptions ref never changes, currentCaptions.cues is set directly which doesnt trigger a rerender
      setTimeout(() => {
        if (currentCaptions.cues) setCues(Array.from(currentCaptions.cues))
      }, 100)
    }
  }, [currentCaptions, trackList, captionFiles, ready])

  const handleSeek = start => {
    childRef.current.seekTo(start)
  }

  const renderParagraphs = paragraphs =>
    paragraphs
      .map((paragraph, index) => {
        return `<p
          id='paragraph-${index + 1}'
          class='transcript-paragraph'
        >${paragraph}</p>`
      })
      .join('')

  const renderCues = cues => {
    let paragraphIndexes = []
    cues.forEach((cue, index) => {
      if (cue.id.endsWith('p')) paragraphIndexes.push(index)
    })

    const paragraphs = paragraphIndexes.reduce((acc, value, index) => {
      if (index === paragraphIndexes.length) return acc
      const nextValue = paragraphIndexes[index + 1]
      const lastValue = cues[cues.length - 1].id

      return [
        ...acc,
        cues
          .slice(value, nextValue ? nextValue : lastValue)
          .map(cue => {
            return `<span>${cue.text}</span>`
          })
          .join(' ')
      ]
    }, [])

    return (
      <div
        className={`transcript-paragraph-container`}
        dangerouslySetInnerHTML={{ __html: renderParagraphs(paragraphs) }}
      />
    )
  }
  // cues.map((cue, index) => {
  //   //formatting to HH:MM:SS time
  //   const formatTime = new Date(null)
  //   formatTime.setSeconds(cue.startTime)
  //   const startTime = formatTime.toISOString().substr(14, 5)
  //   const pStart = cue.id.endsWith('pStart')

  //   return (
  //     <>
  //       <li key={cue.startTime + cue.text}>
  //         <button
  //           aria-describedby='srCueTime   '
  //           onClick={() => handleSeek(cue.startTime)}
  //         >
  //           <span className='cue-time'>{startTime}</span>
  //           {cue.text}
  //           <span id='srCueTime' className='sr-only'>
  //             Set video to {cue.startTime} seconds
  //           </span>
  //         </button>
  //       </li>
  //     </>
  //   )
  // })

  return (
    <>
      <div
        className={`transcripts-container${
          accordion ? ' accordion-transcript' : ' modal-transcripts'
        }`}
      >
        <div className='transcripts'>
          {!accordion && <h2>Transcript</h2>}
          {cues ? renderCues(cues) : ''}
        </div>
      </div>
    </>
  )
}
