import React, { useRef, useEffect, useState, memo } from 'react'
import { handlePlayPause } from '../Controls/handlerFunctions'
import AudioTrack from '../Quiz/AudioTrack'
import {
  usePlayer,
  usePauseEffect,
  useTimeoutTimelineEvent
} from '../Media/Media'
import useMeasure from 'react-use-measure'
import PieTimer from '../PieTimer/PieTimer'
import MuteButton from '../Controls/MuteButton'

const Chalkboard = props => {
  const { chalkboard } = props

  const [loaded, setLoaded] = useState(0)
  const [coloredIn, setColoredIn] = useState(false)
  const [ref, bounds] = useMeasure()
  const [isDrawing, setIsDrawing] = useState(false)

  const coloredRef = useRef(false)
  const containerRef = useRef()
  const canvasRef = useRef()
  const canvas2Ref = useRef()
  const abImg = useRef()
  const cdImg = useRef()
  const chalkImg = useRef()
  //const isDrawing = useRef(false)
  const coords = useRef([])
  const animationId = useRef(null)

  const mouseRef = useRef(null)

  const player = usePlayer()
  const exitChalkboard = () => handlePlayPause(player)

  usePauseEffect(chalkboard)
  useTimeoutTimelineEvent(chalkboard, { shouldPlay: true })

  const draw = () => {
    const canvas = canvasRef.current
    const canvas2 = canvas2Ref.current
    const chalk = chalkImg.current
    const ab = abImg.current
    const cd = cdImg.current

    const container = containerRef.current

    if (!canvas || !canvas2 || !container) return

    const bounds = container.getBoundingClientRect()

    const { width, height } = bounds

    canvas.width = width
    canvas.height = height

    canvas2.width = width
    canvas2.height = height

    const context = canvas.getContext('2d')
    const context2 = canvas2.getContext('2d')

    context.clearRect(0, 0, width, height)
    context2.clearRect(0, 0, width, height)

    context.drawImage(chalk, 0, 0, 1920, 1080, 0, 0, width, height)
    context.globalAlpha = 0.09
    context.drawImage(ab, 0, 0, 1920, 1080, 0, 0, width, height)
    context.drawImage(cd, 0, 0, 1920, 1080, 0, 0, width, height)
    context.globalAlpha = 1

    const coordinates = [...coords.current]

    // if user has drawn
    if (coordinates.length > 0 && !coloredRef.current) {
      // mask with "AB" png
      context2.drawImage(ab, 0, 0, 1920, 1080, 0, 0, width, height)
      context2.globalCompositeOperation = 'source-in'

      // add scribbles
      const [firstCoord, ...restCoords] = coordinates
      context2.strokeStyle = 'white'
      context2.lineWidth = 45
      context2.lineJoin = 'round'
      context2.lineCap = 'round'
      context2.beginPath()
      context2.moveTo(...firstCoord)
      restCoords.forEach(coord => context2.lineTo(...coord))
      context2.stroke()
    } else if (coloredRef.current) {
      context2.drawImage(ab, 0, 0, 1920, 1080, 0, 0, width, height)
      context2.globalCompositeOperation = 'source-in'

      context2.beginPath()
      context2.arc(
        width * 0.35,
        height * 0.4,
        height * 0.21 * 1,
        0,
        2 * Math.PI,
        false
      )
      context2.fillStyle = 'white'
      context2.fill()
    }

    if (!coloredRef.current) {
      // cheaply estimate average colored in space
      const data = context2.getImageData(
        width * 0.25,
        height * 0.3,
        height * 0.371,
        height * 0.2125
      ).data
      let iterations = 0
      let color = 0
      for (let i = 0; i < data.length; i += 90) {
        iterations++
        color += data[i]
      }
      let progress = color / iterations / 255

      if (progress > 0.6) {
        setColoredIn(true)
      }
    }

    //context.drawImage(canvas2, 0, 0, width, height, 0, 0, width, height)
    animationId.current = window.requestAnimationFrame(draw)
  }

  const init = () => {
    animationId.current = window.requestAnimationFrame(draw)
  }

  useEffect(() => {
    if (coloredIn) {
      coloredRef.current = true
      if (!isDrawing) setTimeout(exitChalkboard, 500) // needs to exit after onMouseUp event has fired. If not, mouse events will sometimes break if chalkboard component mounts again
    }
  }, [coloredIn, isDrawing])

  useEffect(() => {
    if (loaded >= 3) init()
    return () => window.cancelAnimationFrame(animationId.current)
  }, [loaded])

  const moveChalkMouse = event => {
    const { layerX: x, layerY: y } = event
  }

  useEffect(() => {
    if (!chalkboard) {
      setLoaded(0)
      setColoredIn(false)
      coords.current = []
      coloredRef.current = false
      animationId.current = null
      if (containerRef.current)
        containerRef.current.removeEventListener('mousemove', moveChalkMouse)
    } else {
      containerRef.current.addEventListener('mousemove', moveChalkMouse)
    }
  }, [chalkboard])

  useEffect(() => {}, [])

  const handleMouseDown = evt => {
    //evt.nativeEvent.preventDefault()
    setIsDrawing(true)
  }

  const handleMouseUp = evt => {
    //evt.nativeEvent.preventDefault()
    setIsDrawing(false)
  }

  const handleTouchMove = event => {
    if (isDrawing && !coloredIn) {
      const { target, targetTouches } = event.nativeEvent
      const rect = target.getBoundingClientRect()
      const x = targetTouches[0].pageX - rect.left
      const y = targetTouches[0].pageY - rect.top

      coords.current = [...coords.current, [x, y]]
    }
  }

  const handleMouseMove = event => {
    const { layerX, layerY } = event.nativeEvent

    const chalkMouse = mouseRef.current
    chalkMouse.style.left = layerX + 'px'
    chalkMouse.style.top = layerY + 'px'

    if (isDrawing && !coloredIn) {
      coords.current = [...coords.current, [layerX, layerY]]
    }
  }

  return (
    !!chalkboard && (
      <div
        ref={containerRef}
        style={{
          fontSize: bounds.width / 100,
          opacity: 1,
          width: '100%',
          height: '100%',
          zIndex: 999,
          position: 'absolute',
          pointerEvents: 'auto'
        }}
      >
        <h2
          className='chalkboard-heading'
          style={{
            position: 'absolute',
            top: '5%',
            left: '50%',
            transform: 'translateX(-50%)',
            fontSize: '2.7em',
            color: 'white',
            backgroundColor: 'transparent',
            userSelect: 'none'
          }}
        >
          Use the chalk to color in the parts we're talking about.
        </h2>
        <AudioTrack src='https://cdn.aetnamedicarebasics.com/audio/coloring-vo.mp3' />
        <AudioTrack
          loop
          src='https://cdn.aetnamedicarebasics.com/audio/quiz-bg-music.mp3'
        />
        <img
          style={{ position: 'absolute', left: -9999 }}
          ref={abImg}
          src='https://cdn.aetnamedicarebasics.com/chalkboard/chalkboard-ab.png'
          onLoad={() => setLoaded(loaded => loaded + 1)}
          alt=''
          crossOrigin='anonymous'
        />
        <img
          style={{ position: 'absolute', left: -9999 }}
          ref={cdImg}
          src='https://cdn.aetnamedicarebasics.com/chalkboard/chalkboard-cd.png'
          onLoad={() => setLoaded(loaded => loaded + 1)}
          alt=''
          crossOrigin='anonymous'
        />
        <img
          style={{ position: 'absolute', left: -9999 }}
          ref={chalkImg}
          src='https://cdn.aetnamedicarebasics.com/chalkboard/chalkboard-medicare.png'
          onLoad={() => setLoaded(loaded => loaded + 1)}
          alt=''
          crossOrigin='anonymous'
        />
        <div
          ref={ref}
          style={{
            opacity: loaded >= 3 ? 1 : 0,
            width: '100%',
            height: '100%',
            transition: 'opacity 0.5s'
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              background: 'linear-gradient(0deg, #024a87 0%, #193665 100%)',
              width: '100%',
              height: '100%',
              zIndex: -1
            }}
          />
          <canvas
            ref={canvasRef}
            style={{ width: '100%', height: '100%' }}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onTouchStart={handleMouseDown}
            onTouchEnd={handleMouseUp}
            onMouseMove={handleMouseMove}
            onTouchMove={handleTouchMove}
          />
          <canvas
            ref={canvas2Ref}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              pointerEvents: 'none'
            }}
          />
        </div>

        <PieTimer
          size='3em'
          color='white'
          seconds={chalkboard ? chalkboard.props.duration : 0}
          style={{ position: 'absolute', right: '2rem', top: '2rem' }}
        />
        <button
          className='green-btn'
          style={{
            opacity: loaded && !coloredIn ? 1 : 0,
            transition: 'all 0.25s',
            position: 'absolute',
            left: '50%',
            top: '78%',
            transform: 'translateX(-50%)'
          }}
          onClick={exitChalkboard}
        >
          Continue
        </button>

        <MuteButton active={!!chalkboard} chalkboard />

        <div
          ref={mouseRef}
          className='chalk-mouse'
          style={{
            position: 'absolute',
            transform: 'translate(-12px, -12px)',
            pointerEvents: 'none',
            width: 75,
            height: 75,
            zIndex: 9999
          }}
        >
          <svg
            className='shadow'
            style={{
              position: 'absolute',
              left: -20,
              top: -20,
              zIndex: -1,
              transition: 'transform 0.25s',
              transform: isDrawing
                ? 'scale(0.75) translate(2px, -3px)'
                : 'translate(8px, 4px)'
            }}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 50.6 56'
          >
            <ellipse
              cx='5'
              cy='20'
              rx='15'
              ry='6'
              transform='rotate(-35 0 0)'
              fill='rgb(0,0,0,.1)'
            />
          </svg>
          <svg
            style={{
              position: 'absolute',
              transition: 'left 0.25s, top 0.25s',
              top: isDrawing ? 0 : 15,
              left: isDrawing ? 0 : 15,
              width: 75,
              height: 75
            }}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 50.6 56'
          >
            <path
              d='M21.5 1.1c-2-2.3-8.3-.9-14 3.4s-8.7 9.9-7 12.6l28.3 37.4 21-15.9L21.6 1.1z'
              fill='#fff'
            />
            <ellipse
              cx='41'
              cy='48.5'
              rx='13.2'
              ry='5.9'
              transform='rotate(-37.1 37.5 50)'
              fill='#c2d9ff'
            />
          </svg>
        </div>
      </div>
    )
  )
}

export default memo(Chalkboard)
