import React, { useRef, useState, useEffect } from 'react'

const t = 30

const PieTimer = props => {
  const { seconds, size, style } = props

  const [alpha, setAlpha] = useState(360)
  const intervalProgress = useRef(seconds * 1000)
  const intervalTimer = useRef(0)

  const getData = () => {
    const clamped = (alpha * 0.99) % 360
    var r = (clamped * Math.PI) / 180,
      x = Math.sin(r) * 125,
      y = Math.cos(r) * -125,
      mid = clamped > 180 ? 1 : 0,
      anim = 'M 0 0 v -125 A 125 125 1 ' + mid + ' 1 ' + x + ' ' + y + ' z'
    return anim
  }

  const draw = () => {
    intervalProgress.current -= t

    const secondsLeft = intervalProgress.current / 1000

    if (intervalProgress.current >= 0) {
      setAlpha((secondsLeft / seconds) * 360)
    } else {
      setAlpha(0)
    }
  }

  useEffect(() => {
    if (seconds > 0) {
      intervalProgress.current = seconds * 1000
      intervalTimer.current = setInterval(draw, t)
    } else {
      clearInterval(intervalTimer.current)
    }

    return () => clearInterval(intervalTimer.current)
  }, [seconds])

  return (
    <svg width={size} height={size} viewBox={`0 0 250 250`} style={style}>
      <path
        fill={props.color || 'white'}
        // id='loader'
        transform='translate(125, 125) scale(-1, 1)'
        transformorigin='center'
        d={getData()}
      />
    </svg>
  )
}

export default PieTimer
