import { memo } from 'react'

import { getProgressFromSeconds } from '../Video/handleTimelineEvents'

const ChapterBars = ({ isMouseDown, chapterList, chapterPlaybackHover, playedSeconds, loadedSeconds, duration }) => {
	return chapterList.map((chapter, index) => {
      const chapterDuration = chapter.endTime - chapter.startTime
      const chapterWidth = parseFloat((chapterDuration / duration) * 100) || 0
      const loadedWidth = getProgressFromSeconds(loadedSeconds, chapter) * 100
      const playedWidth = getProgressFromSeconds(playedSeconds, chapter) * 100
      const isHovering = chapterPlaybackHover > chapter.startTime &&
        chapterPlaybackHover < chapter.endTime

      return <div
            key={chapter.startTime.toString() + chapter.endTime.toString()}
            style={{width: `calc(${chapterWidth}% - 2px)`}}
            id={`chapter-${chapter.startTime}`}
            className={`chapter-bar-container${
              isHovering ? ' hovered' : ''
            }`}
          >
        <div className='progress-list'>
          <div className={`load-progress`} style={{width: loadedWidth + '%'}} />
          <div className={`playback-progress`} style={{width: playedWidth + '%'}} />
        </div>
      </div>
    })
}

export default memo(ChapterBars)