import React, { useState, useRef } from 'react'
import {
  handlePlayPause,
  handleSeek,
  handleVolumeChange,
  showUi
} from './handlerFunctions'

import ChapterBars from './ChapterBars'
import {
  usePlayer,
  usePlayerState,
  useTimeoutTimelineEvent
} from '../Media/Media'

export default function ProgressBar (props) {
  const { chapterList, currentChapter, timelineDemo } = props

  const [isMouseDown, setMouseDown] = useState()
  const [chapterPlaybackHover, setChapterPlaybackHover] = useState(false)
  const wasPlaying = useRef(false)

  const player = usePlayer()

  const {
    playing,
    playedSeconds,
    activeSeconds,
    loadedSeconds,
    duration,
    volume
  } = usePlayerState()

  const onChange = event => {
    handleSeek(player, { value: event.target.value, stop: isMouseDown })
  }

  const handleInputKeyDown = event => {
    // Need to stop Video.js events from interfering with arrow keys
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault()
        showUi(player)
        if (volume < 0.95) handleVolumeChange(player, volume * 100 + 5)
        if (volume >= 0.95) handleVolumeChange(player, 100)
        break
      case 'ArrowDown':
        event.preventDefault()
        showUi(player)
        if (volume > 0.05) handleVolumeChange(player, volume * 100 - 5)
        if (volume <= 0.05) handleVolumeChange(player, 0)
        break
      case 'ArrowLeft':
        event.stopPropagation()
        showUi(player)
        break
      case 'ArrowRight':
        event.stopPropagation()
        showUi(player)
        break
      default:
        break
    }
  }

  const handleMouseDown = event => {
    setMouseDown(true)
    wasPlaying.current = playing


    const { layerX, target } = event.nativeEvent
    const seconds = layerX / target.getBoundingClientRect().width * duration
    
    setChapterPlaybackHover(seconds)
    player.dispatch({ type: 'ACTIVE_SECONDS', activeSeconds: seconds })
    handleSeek(player, { value: seconds, stop: true })
  }

  const handleMouseUp = () => {
    setMouseDown(false)

    // restore play status

    handleSeek(player, {value: activeSeconds })
    player.dispatch({ type: wasPlaying.current ? 'PLAY' : 'STOP' })
  }

  const handleTimelineTouch = event => {
    const { target } = event.nativeEvent
    const rect = target.getBoundingClientRect()
    const x = event.nativeEvent.targetTouches[0].pageX - rect.left
    const seconds = (x / rect.width) * duration

    setChapterPlaybackHover(seconds)
    showUi(player)

    if (isMouseDown) {
      handleSeek(player, {value: seconds, stop: true })
      player.dispatch({type: 'ACTIVE_SECONDS', activeSeconds: seconds})
    }
  }

  const handleTimelineHover = event => {
    const { layerX, target } = event.nativeEvent

    const seconds = (layerX / target.getBoundingClientRect().width) * duration

    setChapterPlaybackHover(seconds)
    showUi(player)

    if (isMouseDown) {
      handleSeek(player, {value: seconds, stop: true })
      player.dispatch({type: 'ACTIVE_SECONDS', activeSeconds: seconds})
    }
  }

  const handleInputMouseOut = () => {
    setChapterPlaybackHover(null)
    player.dispatch({ type: 'ACTIVE_SECONDS', activeSeconds: null })
  }

  useTimeoutTimelineEvent(timelineDemo)

  return (
    <div
      className={`progress-bar-container`}
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
      onMouseUp={handleMouseUp}
    >
      <div className='progress-bar'>
        <div className={`chapter-bars-list ${!!timelineDemo ? ' wave' : ''}`}>
          <ChapterBars
            chapterList={chapterList}
            chapterPlaybackHover={chapterPlaybackHover}
            playedSeconds={activeSeconds || playedSeconds}
            loadedSeconds={loadedSeconds}
            duration={duration}
          />
        </div>
        <input
          aria-label='seeker bar'
          aria-valuemin='0'
          aria-valuenow={event =>
            playedSeconds ? Math.round(playedSeconds * 10) / 10 : 0
          }
          aria-valuemax={Math.round(duration * 10) / 10}
          // aria-valuetext={currentChapter ? currentChapter.title : ''}
          id='seeker'
          type='range'
          min={0}
          value={activeSeconds || playedSeconds}
          max={duration}
          step={0.1}
          onMouseOver={handleTimelineHover}
          onMouseMove={handleTimelineHover}
          onMouseOut={handleInputMouseOut}
          onTouchMove={handleTimelineTouch}
          onInput={onChange}
          onChange={onChange}
          // onKeyDown={handleInputKeyDown}
        />
      </div>
    </div>
  )
}
