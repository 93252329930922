import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { Modal } from '@moderati/mod-components'
import { createHtmlPortalNode, InPortal, OutPortal } from 'react-reverse-portal'
import Video from '../Video/Video'
import { setCueWidth, showCaptionTrack } from '../Controls/handlerFunctions'

import trackEn from '../../assets/videos/aie/captions-en.vtt'
import trackChapters from '../../assets/videos/aie/chapters.vtt'

import trackEnV2 from '../../assets/videos/aieV2/captions-en.vtt'
import trackChaptersV2 from '../../assets/videos/aieV2/chapters.vtt'

import timelineEventsJson from '../../json/videoTimelineEvents.json'
import timelineEventsJsonV2 from '../../json/videoTimelineEventsV2.json'

import { Media } from '../Media/Media'

import '../../css/styles.css'
import '../../css/fonts.css'

export default function Embed (props) {
  const { partD = true } = props

  let videoMp4 =
    'https://cdn.aetnamedicarebasics.com/videos/medicare-basics.mp4'
  let captionTrackEnSrc = trackEn
  let chapterTrackEnSrc = trackChapters
  let timelineEventsSrc = timelineEventsJson

  //if video is version without Medicare Part D chapter, then we want to import different video, caption, and videoTimelineEvents files
  if (!partD) {
    captionTrackEnSrc = trackEnV2
    chapterTrackEnSrc = trackChaptersV2
    videoMp4 =
      'https://cdn.aetnamedicarebasics.com/videos/medicare-basics-v2.mp4'
    timelineEventsSrc = timelineEventsJsonV2
  }

  const tracks = [
    { kind: 'captions', src: captionTrackEnSrc, srcLang: 'en', default: true },
    { kind: 'chapters', src: chapterTrackEnSrc, srcLang: 'en', default: true }
  ]

  const [currentCaptions, setCurrentCaptions] = useState(null)
  const [playerView, setPlayerView] = useState('default')
  const [isCaptionsMenuOpen, setIsCaptionsMenuOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [trackList, setTrackList] = useState(null)
  const [videoHover, setVideoHover] = useState(true)
  const [firstRender, setFirstRender] = useState(true) //to prevent playerVeiwButton getting focus on first render

  const chapterListRef = useRef(null)
  const childRef = useRef(null)
  const playedSecondsRef = useRef(null)
  const trackListRef = useRef(null)

  useEffect(() => {
    if (playerView === 'expanded') setIsModalOpen(true)
    else setIsModalOpen(false)
  }, [playerView])

  useEffect(() => {
    if (isModalOpen === false) setPlayerView('default')

    if (!isModalOpen && !firstRender) {
      setTimeout(() => {
        document.getElementById('playerViewButton').focus()
      }, 100)
    }
    if (isModalOpen === true) {
      setPlayerView('expanded')
      setFirstRender(false)
    }
  }, [isModalOpen])

  useEffect(() => {
    setCueWidth(75, trackList)
  }, [currentCaptions, trackList])

  useEffect(() => {
    if (currentCaptions && trackList) {
      showCaptionTrack(currentCaptions.language, trackList)
    }
  }, [currentCaptions])

  const handleRequestClose = () => {
    setIsModalOpen(false)
  }

  const portalNode = React.useMemo(() => createHtmlPortalNode(), [])

  return (
    <div className='aetna-medicare-player-wrapper'>
      <Media>
        <InPortal node={portalNode}>
          <Video
            chapterListRef={chapterListRef}
            currentCaptions={currentCaptions}
            setCurrentCaptions={setCurrentCaptions}
            isCaptionsMenuOpen={isCaptionsMenuOpen}
            setIsCaptionsMenuOpen={setIsCaptionsMenuOpen}
            tracks={tracks}
            trackList={trackList}
            playedSecondsRef={playedSecondsRef}
            playerView={playerView}
            setPlayerView={setPlayerView}
            timelineEventsJson={timelineEventsSrc}
            tracklist={trackList}
            setTrackList={setTrackList}
            videoHover={videoHover}
            src={videoMp4}
            setVideoHover={setVideoHover}
          />
        </InPortal>

        {isModalOpen !== true && <OutPortal node={portalNode} />}
        <Modal
          ariaLabel='Expanded player view'
          modalId='aetna-medicare-player-modal'
          overlayClassName='aetna-medicare-player-modal-overlay'
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          modalTitle=''
          closeIcon={true}
          modalWidth='98vw'
          modalHeight='auto'
          modalBackground='black'
          onRequestClose={handleRequestClose}
          // parentSelector={document.getElementById('aetna-medicare-101-player')}
        >
          {isModalOpen === true && <OutPortal node={portalNode} />}
        </Modal>
      </Media>
    </div>
  )
}
