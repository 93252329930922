import { useState, useEffect } from 'react'
import {
  disableCaptions,
  handleKeyDown,
  handleSelectedTrack
} from './handlerFunctions'

export default function CaptionsMenu (props) {
  const {
    areSettingsOpen,
    setAreSettingsOpen,
    captionFiles,
    setCaptionFiles,
    ccButtonRef,
    currentCaptions,
    setCurrentCaptions,
    currentTrack,
    setCurrentTrack,
    isCaptionsMenuOpen,
    setIsCaptionsMenuOpen,
    firstCcRef,
    lastCcRef,
    reverseCcBlur,
    setReverseCcBlur,
    trackList
  } = props

  const [ccBlur, setCcBlur] = useState(false)
  const [captionsDisabled, setCaptionsDisabled] = useState(true)

  const handleLangClick = track => {
    setCaptionsDisabled(false)
    setCurrentCaptions(track)
    handleSelectedTrack(track)
  }

  const handleDisableClick = () => {
    setCaptionsDisabled(true)
    disableCaptions()
    setIsCaptionsMenuOpen(false)
  }

  const handleCKey = event => {
    if (event.key === 'c' || event.key === 'C') setIsCaptionsMenuOpen(false)
  }

  const handleFirstBlur = () => {
    if (reverseCcBlur === true) {
      ccButtonRef.current.focus()
    }
  }

  useEffect(() => {
    if (trackList) {
      const filterCaptions = trackList.filter(
        track => track.kind === 'captions'
      )

      if (filterCaptions) {
        filterCaptions.forEach(track => {
          const language = track.language.substring(0, 2)
          switch (language) {
            case 'en':
              track.name = 'English'
              break
            case 'es':
              track.name = 'Español'
              break
            case 'fr':
              track.name = 'Français'
              break
            default:
              track.name = language.toUpperCase()
          }
        })
        setCaptionFiles(filterCaptions)
      }
    }
  }, [trackList])

  useEffect(() => {
    if (ccButtonRef && ccBlur === true) ccButtonRef.current.focus()
  }, [ccBlur])

  useEffect(() => {
    setTimeout(() => {
      if (currentCaptions && captionsDisabled) disableCaptions()
    }, 500)
  }, [currentCaptions])

  const renderCaptionTracks = tracks =>
    tracks.map((track, index) => {
      return (
        <li key={track.language + track.name}>
          <button
            ref={index === 0 ? firstCcRef : null}
            onFocus={() => setCcBlur(false) && setReverseCcBlur(false)}
            className={
              currentCaptions.language === track.language && !captionsDisabled
                ? ' active'
                : ''
            }
            onBlur={index === 0 ? handleFirstBlur : null}
            onKeyDown={event => {
              handleKeyDown(event, setReverseCcBlur, setIsCaptionsMenuOpen)
              handleCKey(event)
              setIsCaptionsMenuOpen(false)
            }}
            lang={track.language}
            tabIndex={isCaptionsMenuOpen === true ? 0 : -1}
            onClick={() => {
              handleLangClick(track)
              setIsCaptionsMenuOpen(false)
            }}
          >
            {track.name}
          </button>
        </li>
      )
    })

  return (
    <>
      <div
        id='captionsMenu'
        aria-hidden={isCaptionsMenuOpen === true ? 'false' : 'true'}
        aria-describedby='captionsHeading'
        aria-labelledby='captionsButton'
        className={`captions-container${
          isCaptionsMenuOpen === true ? ' active' : ''
        }`}
        style={{ fontSize: '1rem' }}
      >
        <div className={`caption-settings`}>
          <h2 id='captionsHeading'>Subtitles/CC</h2>
          <ul id='captionsList'>
            {currentCaptions ? renderCaptionTracks(captionFiles) : ''}
            <button
              ref={lastCcRef}
              tabIndex={isCaptionsMenuOpen === true ? 0 : -1}
              onBlur={() => (reverseCcBlur === false ? setCcBlur(true) : null)}
              onClick={handleDisableClick}
              onFocus={() => setReverseCcBlur(false)}
              className={captionsDisabled === true ? ' active' : ''}
              onKeyDown={event => {
                handleKeyDown(event, setReverseCcBlur, setIsCaptionsMenuOpen)
                setIsCaptionsMenuOpen(false)
              }}
            >
              Off
            </button>
          </ul>
        </div>
      </div>
    </>
  )
}
