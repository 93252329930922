let hoverTimer = null

export const showUi = ({ dispatch }) => {
  dispatch({ type: 'SHOW_UI' })

  hoverTimer && clearTimeout(hoverTimer)
  hoverTimer = setTimeout(() => {
    dispatch({ type: 'HIDE_UI' })
  }, 3500)
}

export const hideUi = ({ dispatch }) => {
  hoverTimer && clearTimeout(hoverTimer)
  dispatch({ type: 'HIDE_UI' })
}

export const controlsMouseOver = ({ dispatch }) => {
  dispatch({ type: 'CONTROLS_MOUSEOVER' })
}

export const controlsMouseOut = ({ dispatch }) => {
  dispatch({ type: 'CONTROLS_MOUSEOUT' })
}

export const handleKeyDown = (event, blurState, openState) => {
  switch (event.key) {
    case 'Tab':
      if (event.shiftKey) blurState(true)
      break
    case 'Esc':
    case 'Escape':
      openState(false)
      break
    case 'ArrowUp':
      break
    case 'ArrowDown':
      break
    default:
  }
}

// Controls.js handlers
export const handleFastForward = (
  currentSpeed,
  setCurrentSpeed,
  ffwCount,
  setFfwCount,
  setFastForwarding,
  setRewinding,
  setRewindCount,
  setPlaying
) => {
  if (ffwCount < 4) {
    setFfwCount(ffwCount + 1)
    setFastForwarding(true)
    setRewinding(false)
    setRewindCount(1)
    setCurrentSpeed({ speed: currentSpeed.speed + 0.5 })
    setPlaying(true)
  } else {
    setFfwCount(1)
    setCurrentSpeed({ speed: 1, name: 'Normal' })
  }
}

export const handleModalOpen = (
  expandedControls,
  setExpandedControls,
  playerView,
  setPlayerView
) => {
  setExpandedControls(!expandedControls)
  playerView === 'default'
    ? setPlayerView('expanded')
    : setPlayerView('default')
}

export const handlePlaybackSpeed = (speed, setCurrentSpeed) => {
  setCurrentSpeed(speed)
}

export const handlePlayPause = player => {
  const { dispatch } = player

  dispatch({ type: 'TOGGLE_PLAY' })
  dispatch({ type: 'CLEAR_TIMELINE_EVENTS' })
}

export const handleSkip = (player, amount) => {
  const { ref, dispatch } = player
  const playedSeconds = ref.current.getCurrentTime() + amount
  ref.current.seekTo(playedSeconds)
  dispatch({ type: 'PLAYED_SECONDS', playedSeconds })
}

export const handleSeekTo = (player, playedSeconds) => {
  const { ref, dispatch } = player
  ref.current.seekTo(playedSeconds)
  dispatch({ type: 'PLAYED_SECONDS', playedSeconds })
}

// React-player's playback speed doesn't accept a negative value
export const handleRewind = (
  childRef,
  setCurrentTime,
  rewinding,
  setRewinding,
  rewindCount,
  setRewindCount,
  setFfwCount,
  setFastForwarding,
  setPlaying,
  setCurrentSpeed
) => {
  if (rewindCount < 4) {
    setFastForwarding(false)
    setFfwCount(1)
    setRewindCount(rewindCount + 1)
    setRewinding(true)
    setPlaying(false)
    setCurrentSpeed({ speed: 1, name: 'Normal' })
  } else {
    setRewindCount(1)
    setRewinding(false)
  }
}

export const handleVolumeChange = (player, value) => {
  const volume = parseFloat(value / 100)
  player.dispatch({
    type: 'VOLUME',
    volume
  })
}

const getAllTracks = () => {
  const currentVideo = document.getElementById('video-1')
  if (currentVideo) {
    const tracks = Array.from(currentVideo.textTracks)
  }
}

export const handleGetTrackList = (setTrackList, setCurrentCaptions) => {
  const currentVideo = document.getElementById('video-1')
  const userLang = navigator.language

  if (currentVideo) {
    const trackList = Array.from(currentVideo.textTracks)

    if (trackList) {
      setTrackList(trackList)
      setUserLanguageTracks(userLang, trackList, setCurrentCaptions)
    } // trackList
  } // currentVideo
}

//Find the user's browser language, then set the currentCaptions to that if available, else fallback to the default captions
const setUserLanguageTracks = (userLang, trackList, setCurrentCaptions) => {
  const targetLang = userLang.substring(0, 2)

  // Setting captions tracks
  const captionTracks = trackList.filter(track => track.kind === 'captions')
  const userLangCaptionTrack = captionTracks.find(
    track => track.language === targetLang
  )
  const defaultCaptionTrack = captionTracks.find(
    track => track.language === 'en'
  )

  if (userLangCaptionTrack && defaultCaptionTrack) {
    if (userLangCaptionTrack.language !== defaultCaptionTrack.language) {
      setCurrentCaptions(userLangCaptionTrack)
      showCaptionTrack(targetLang, trackList)
    }
    if (userLangCaptionTrack.language === defaultCaptionTrack.language) {
      setCurrentCaptions(defaultCaptionTrack)
    }
  }
  if (!userLangCaptionTrack) {
    setCurrentCaptions(defaultCaptionTrack)
  }

  // Setting chapters tracks
  const chapterTracks = trackList.filter(track => track.kind === 'chapters')
  const userLangChaptersTrack = chapterTracks.find(
    track => track.language === targetLang
  )
  const defaultChaptersTrack = chapterTracks.find(
    track => track.mode === 'hidden'
  )

  // if (userLangChaptersTrack) {
  //   hideChapterTrack(userLangCaptionTrack, trackList)
  // }
}

export const handleSelectedTrack = selectedTrack => {
  const selectedLang = selectedTrack.language
  const targetLang = selectedLang.substring(0, 2)
  const currentVideo = document.getElementById('video-1')
  if (currentVideo) {
    const tracks = Array.from(currentVideo.textTracks)
    if (tracks) {
      showCaptionTrack(targetLang, tracks)
    }
  }
}

export const showCaptionTrack = (lang, trackList) => {
  // Need to make sure only one caption track is showing, so disabling all before setting the one we want showing
  const captionTracks = trackList.filter(track => track.kind === 'captions')
  captionTracks.forEach(track => {
    track.mode = 'disabled'
    track.size = '70%'
  })
  const findLang = captionTracks.find(track => track.language === lang)
  if (findLang) findLang.mode = 'showing'
}

export const handleGetChapters = (player, trackList) => {
  const userLang = navigator.language
  const targetLang = userLang.substring(0, 2)
  const chapterTracks = trackList.filter(track => track.kind === 'chapters')
  const langTrack = chapterTracks.find(track => track.language === targetLang)
  const defaultTrack = chapterTracks.find(track => track.language === 'en')

  let chapters = null
  if (langTrack) chapters = Array.from(langTrack.cues)
  if (!langTrack && defaultTrack) chapters = Array.from(defaultTrack.cues)

  player.dispatch({ type: 'CHAPTER_LIST', chapterList: chapters })

  return chapters
}

// adjust the line (or position) of caption track cues
export const adjustCueLine = (line, trackList) => {
  if (trackList) {
    const captionTracks = trackList.filter(track => track.kind === 'captions')
    const showingTrack = captionTracks.find(track => track.mode === 'showing')
    if (captionTracks && showingTrack) {
      const cues = Array.from(showingTrack.cues)
      cues.forEach(cue => {
        cue.line = line
      })
    }
  }
}

export const setCueWidth = (width, trackList) => {
  if (trackList) {
    const captionTracks = trackList.filter(track => track.kind === 'captions')
    const showingTrack = captionTracks.find(track => track.mode === 'showing')
    if (captionTracks && showingTrack) {
      const cues = Array.from(showingTrack.cues)
      cues.forEach(cue => {
        cue.size = width
        cue.start = 'bottom'
      })
    }
  }
}

// chapter tracks are hidden by default, but the data is still able to be grabbed to set our chapters
export const hideChapterTrack = (track, trackList) => {
  // Need to make sure only one chapter track is showing, so disabling all before setting the one we want showing
  const chapterTracks = trackList.filter(track => track.kind === 'chapters')
  chapterTracks.forEach(track => {
    track.mode = 'disabled'
  })

  track.mode = 'hidden'
}

export const handleHideCaptions = (currentTrack, trackList) => {
  if (trackList && currentTrack) {
    const lang = currentTrack.language
    const filterCaptions = trackList.filter(track => track.kind === 'captions')
    const captionLang = filterCaptions.find(track => track.language === lang)
    disableCaptions(filterCaptions)

    captionLang.mode = 'hidden'
  }
}
export const disableCaptions = () => {
  const currentVideo = document.getElementById('video-1')
  if (currentVideo) {
    const trackList = Array.from(currentVideo.textTracks)
    if (trackList) {
      const captionTracks = trackList.filter(track => track.kind === 'captions')
      if (captionTracks)
        captionTracks.forEach(track => {
          track.mode = 'disabled'
        })
    }
  }
}

// ProgressBar functions

export const handleSeek = (player, { value, stop }) => {
  const { ref, dispatch } = player

  ref.current.seekTo(value)
  dispatch({ type: 'PLAYED_SECONDS', playedSeconds: value })
  dispatch({ type: 'CLEAR_TIMELINE_EVENTS' })

  stop && dispatch({ type: 'STOP' })
}

// Current Chapter Handlers
export const getCurrentChapter = (
  newChapter,
  childRef,
  currentChapter,
  setCurrentChapter
) => {
  setCurrentChapter(newChapter)
  childRef.current.seekTo(newChapter.startTime)
}

export const handlePrevChapter = (player, { chapterList, currentChapter }) => {
  const { ref } = player
  let currentIndex = chapterList.findIndex(
    index => index.text === currentChapter.text
  )

  if (currentIndex > 0) {
    handleSeek(player, { value: chapterList[currentIndex - 1].startTime })
    player.dispatch({
      type: 'CURRENT_CHAPTER',
      chapter: chapterList[currentIndex - 1]
    })
  }
}

export const handleNextChapter = (player, { chapterList, currentChapter }) => {
  const { ref } = player

  let currentIndex = chapterList.findIndex(
    index => index.text === currentChapter.text
  )
  let lastObject = chapterList[chapterList.length - 1]
  let lastIndex = chapterList.findIndex(index => index.text === lastObject.text)

  if (currentIndex < lastIndex) {
    handleSeek(player, { value: chapterList[currentIndex + 1].startTime })
    player.dispatch({
      type: 'CURRENT_CHAPTER',
      chapter: chapterList[currentIndex + 1]
    })
  }
}

export const handleSeekToChapter = (currentChapter, childRef) => {
  childRef.current.seekTo(currentChapter.startTime)
}
