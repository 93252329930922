import React, { useState } from 'react'

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import {
  handleModalOpen,
  handlePlayPause,
  handleKeyDown,
  handleSkip,
  handleVolumeChange,
  controlsMouseOut,
  controlsMouseOver
} from './handlerFunctions'

import { AiOutlineFullscreen } from 'react-icons/ai'
import { AiOutlineFullscreenExit } from 'react-icons/ai'

import { FaPlay } from 'react-icons/fa'
import { FaPause } from 'react-icons/fa'
import { FaRegClosedCaptioning } from 'react-icons/fa'
import { FaVolumeUp } from 'react-icons/fa'
import { FaVolumeMute } from 'react-icons/fa'

import { GrForwardTen } from 'react-icons/gr'
import { GrBackTen } from 'react-icons/gr'

import { MdTheaters } from 'react-icons/md'

import { usePlayer, usePlayerState } from '../Media/Media'
import CaptionsMenu from './CaptionsMenu'
import CurrentChapter from '../CurrentChapter/CurrentChapter'
import ProgressBar from './ProgressBar'

const CurrentTime = React.memo(props => {
  const { playedSeconds, activeSeconds, duration } = usePlayerState()

  const formatTime = seconds => {
    const min = String(parseInt(seconds / 60)).padStart(2, '0')
    const sec = String(parseInt(seconds % 60)).padStart(2, '0')
    return min + ':' + sec
  }

  return (
    <span className='current-time'>
      {formatTime(activeSeconds || playedSeconds)} / {formatTime(duration)}
    </span>
  )
})

export default function Controls (props) {
  const {
    ccButtonRef,
    chapterList,
    currentChapter,
    breakpoint,
    expandedControls,
    setExpandedControls,
    firstCcRef,
    isCaptionsMenuOpen,
    setIsCaptionsMenuOpen,

    isFullscreen,

    playerView,
    setPlayerView,

    OnFullscreenToggle,
    videoHover,

    currentCaptions,
    setCurrentCaptions,
    trackList,
    lastCcRef,
    reverseCcBlur,
    setReverseCcBlur,
    showUi,
    timelineDemo
  } = props
  const [ccBlur, setCcBlur] = useState(false)

  const player = usePlayer()
  const {
    volume,
    muted,
    played,
    playedSeconds,
    playing,
    duration
  } = usePlayerState()

  const handleCcBlur = () => {
    if (isCaptionsMenuOpen) {
      if (reverseCcBlur) lastCcRef.current.focus()
      else firstCcRef.current.focus()
    }
  }

  const handleCcKeyDown = event => {
    if (event.key === 'Escape' || event.key === 'Esc')
      setIsCaptionsMenuOpen(false)
  }

  const playerClasses = [
    'controls',
    playerView !== 'expanded' ? 'default' : '',
    videoHover ? 'active' : ''
  ].join(' ')

  const onChange = event => {
    handleVolumeChange(player, event.target.value)
  }
  const onPlayPause = () => handlePlayPause(player)
  const onSkip = skipSeconds => handleSkip(player, skipSeconds)
  const onSkipBack = () => handleSkip(player, -10)
  const onSkipForward = () => handleSkip(player, 10)

  const toggleMute = () => player.dispatch({ type: 'MUTED', muted: !muted })

  const handleMouseOver = () => controlsMouseOver(player)
  const handleMouseOut = () => controlsMouseOut(player)

  const stopKeyDownPropagation = event => {
    event.stopPropagation()
  }

  const handleArrowKeyDowns = (event, playedSeconds) => {
    if (event.keyCode === 32) event.stopPropagation() //disables playing/pausing video when hitting space
    switch (event.key) {
      case 'ArrowLeft':
        event.preventDefault()
        if (playedSeconds > 1) onSkip(-1)
        break
      case 'ArrowRight':
        event.preventDefault()
        if (playedSeconds < duration) onSkip(1)
        break
      case 'ArrowUp':
        event.preventDefault()
        if (volume < 0.95) handleVolumeChange(player, volume * 100 + 5)
        if (volume >= 0.95) handleVolumeChange(player, 100)
        break
      case 'ArrowDown':
        if (volume > 0.05) handleVolumeChange(player, volume * 100 - 5)
        if (volume <= 0.05) handleVolumeChange(player, 0)
        event.preventDefault()
        break
      default:
        break
    }
  }

  return (
    <div
      id='controls'
      className={playerClasses}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <Row style={{ zIndex: 5 }}>
        <Col xs={12}>
          <ProgressBar
            chapterList={chapterList}
            currentChapter={currentChapter}
            handleArrowKeyDowns={handleArrowKeyDowns}
            stopKeyDownPropagation={stopKeyDownPropagation}
            timelineDemo={timelineDemo}
            showUi={showUi}
          />
        </Col>
        <Row
          style={{
            zIndex: 5,
            padding: 0,
            height: breakpoint === 'mobile' ? '1.25rem' : '1.75rem',
            margin: 0,
            alignItems: 'center'
          }}
        >
          <Col xs={10} md={4}>
            <div
              className='left-controls'
              style={{ paddingTop: breakpoint === 'mobile' ? 0 : 6 }}
            >
              {breakpoint !== 'mobile' && (
                <>
                  <button
                    id='playPause'
                    aria-label={playing === true ? 'pause' : 'play'}
                    onClick={onPlayPause}
                    onKeyDown={handleArrowKeyDowns}
                  >
                    {playing === true ? <FaPause alt='' /> : <FaPlay alt='' />}
                  </button>

                  <button
                    id='skipBack'
                    aria-label='skip backward 10 seconds'
                    onClick={onSkipBack}
                    onKeyDown={handleArrowKeyDowns}
                  >
                    <GrBackTen alt='' />
                  </button>

                  <button
                    aria-label='skip forward 10 seconds'
                    onClick={onSkipForward}
                    onKeyDown={handleArrowKeyDowns}
                  >
                    <GrForwardTen alt='' />
                  </button>
                  <button
                    aria-label={muted === true ? 'unmute' : 'mute'}
                    onClick={toggleMute}
                    onKeyDown={handleArrowKeyDowns}
                  >
                    {muted === true ? (
                      <FaVolumeMute alt='' />
                    ) : (
                      <FaVolumeUp alt='' />
                    )}
                  </button>
                  <div className='volume-container'>
                    <div aria-hidden='true' id='volumeTrack'>
                      <div
                        id='volumeBg'
                        style={{ width: `calc(100% - 4px)` }}
                      />
                      <div
                        id='volumeValue'
                        style={{ width: `calc(${volume * 100}% - 4px)` }}
                      />
                    </div>
                    <label htmlFor='volume' className='sr-only'>
                      Volume Percent
                    </label>
                    <input
                      id='volume'
                      name='volume'
                      type='range'
                      min='0'
                      max='100'
                      value={volume * 100}
                      step='5'
                      onInput={onChange}
                      onChange={onChange}
                    />
                  </div>
                </>
              )}

              <CurrentTime />
              {breakpoint === 'mobile' && (
                <CurrentChapter
                  breakpoint={breakpoint}
                  chapterList={chapterList}
                  currentChapter={currentChapter}
                  chapterDemo={timelineDemo}
                  played={played}
                />
              )}
            </div>
          </Col>

          {breakpoint !== 'mobile' && (
            <Col xs={4} className='d-none d-md-block'>
              <CurrentChapter
                chapterList={chapterList}
                currentChapter={currentChapter}
                chapterDemo={timelineDemo}
                played={played}
              />
            </Col>
          )}

          <Col xs={2} md={4} style={{ justifyContent: 'center' }}>
            <div
              className='right-controls'
              style={{ paddingTop: breakpoint === 'mobile' ? 0 : 6 }}
            >
              <button
                ref={ccButtonRef}
                id='captionsButton'
                aria-label='Captions menu'
                aria-expanded={isCaptionsMenuOpen === true ? 'true' : 'false'}
                onClick={() => setIsCaptionsMenuOpen(!isCaptionsMenuOpen)}
                onBlur={handleCcBlur}
                onFocus={() => setReverseCcBlur(false)}
                onKeyDown={event =>
                  handleKeyDown(event, setReverseCcBlur, setIsCaptionsMenuOpen)
                }
              >
                <FaRegClosedCaptioning alt='' />
              </button>

              {playerView !== 'expanded' ? (
                <button
                  aria-label={
                    playerView !== 'expanded'
                      ? 'Expand player view'
                      : 'Default player view'
                  }
                  id='playerViewButton'
                  title={'player view'}
                  onClick={() =>
                    handleModalOpen(
                      expandedControls,
                      setExpandedControls,
                      playerView,
                      setPlayerView
                    )
                  }
                  onKeyDown={handleArrowKeyDowns}
                >
                  <MdTheaters alt='' />
                </button>
              ) : (
                ''
              )}

              <button
                aria-label={`${
                  isFullscreen === true ? 'deactivate' : 'activate'
                } fullscreen`}
                onClick={OnFullscreenToggle}
                onKeyDown={handleArrowKeyDowns}
                title='full screen'
              >
                {isFullscreen === true ? (
                  <AiOutlineFullscreenExit alt='' />
                ) : (
                  <AiOutlineFullscreen alt='' />
                )}
              </button>
            </div>
          </Col>
        </Row>
      </Row>
    </div>
  )
}
