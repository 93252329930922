import React from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { IoIosArrowForward } from 'react-icons/io'
import {
  handlePrevChapter,
  handleNextChapter
} from '../Controls/handlerFunctions'
import { usePlayer, usePlayerState } from '../Media/Media'

export default function CurrentChapter (props) {
  const {
    chapterList,
    chapterDemo,
    breakpoint
  } = props

  const currentChapterRef = 0
  const player = usePlayer()

  const { currentChapter } = usePlayerState()

  const onPrevClick = () => handlePrevChapter(player, { chapterList, currentChapter })
  const onNextClick = () => currentChapter.text !== lastChapter.text && 
      handleNextChapter(player, { chapterList, currentChapter })

  const firstChapter = chapterList[0]
  const lastChapter = chapterList[chapterList.length - 1]

  return currentChapter ? (
    <>
      <div className='current-container'>

        <div className={`current-container border-flash ${chapterDemo ? 'animating' : ''}`}>
          {breakpoint !== 'mobile' && <button
            id='prevButton'
            aria-label='previous chapter'
            aria-describedby={
              currentChapter.text === firstChapter.text
                ? 'firstChapterDescription'
                : null
            }
            aria-disabled={
              currentChapter.text === firstChapter.text ? true : false
            }
            onClick={onPrevClick}
          >
            <IoIosArrowBack value={{ color: 'white' }} />
            {currentChapter.text === firstChapter.text ? (
              <span id='firstChapterDescription' className='sr-only'>
                You are currently on the first chapter
              </span>
            ) : (
              ''
            )}
          </button>}

          <span
            className='text'
            style={{textTransform: 'unset'}}
            dangerouslySetInnerHTML={{ __html: currentChapter.text }}
          ></span>

          <button
            id='nextButton'
            aria-label={`next chapter`}
            aria-disabled={
              currentChapter.text === lastChapter.text ? true : false
            }
            aria-describedby={
              currentChapter.text === lastChapter.text
                ? 'lastChapterDescription'
                : null
            }
            onClick={onNextClick}
          >
            <IoIosArrowForward className='icon' />
            {currentChapter.text === lastChapter.text ? (
              <span id='lastChapterDescription' className='sr-only'>
                You are currently on the last chapter
              </span>
            ) : (
              ''
            )}
          </button>

        </div>
      </div>
    </>
  ) : (
    ''
  )
}
