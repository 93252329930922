import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import ReactDOM from 'react-dom'

import { IoChevronForward } from 'react-icons/io5'
import Embed from './components/Embed/Embed'
import { Helmet } from 'react-helmet'
import './css/styles.css'
import './css/fonts.css'
import CopyEmbedForm from './components/CopyEmbedForm/CopyEmbedForm'
import TimeStamps from './components/Preview/TimeStamps'
import Navbar from './components/PublicViewComponents/Navbar'
import Footer from './components/PublicViewComponents/Footer'

function App () {
  return (
    <Router>
      <Route path='/' exact component={PublicView} />
      <Route path='/2' exact component={PublicViewV2} />
      <Route path='/embed' exact component={EmbedPage} />
      <Route path='/embed2' exact component={EmbedPageV2} />
    </Router>
  )
}

// Routes for the public pages that Aetna shares with it's plan members
const PublicView = () => {
  return (
    <>
      <header className='Aetna-player-app-header'>
        <Navbar />
        <div className='title-banner'>
          <h1 className='title'>
            Understanding the basics of Medicare and Medicare Advantage
          </h1>
        </div>
        <div className='preview-player'>
          <Embed partD={false} />
        </div>
      </header>
      <Footer />
    </>
  )
}

const PublicViewV2 = () => {
  return (
    <>
      <header className='Aetna-player-app-header'>
        <Navbar />
        <div className='title-banner'>
          <h1 className='title'>
            Understanding the basics of Medicare and Medicare Advantage
          </h1>
        </div>
        <div className='preview-player'>
          <Embed />
        </div>
      </header>
      <Footer />
    </>
  )
}

// Routes for the internal page that allow users to copy the embed code
const EmbedPage = () => {
  return (
    <>
      <div className='Aetna-Player-App'>
        <Helmet>
          <title>Medicare and Medicare Advantage - V2 - Video Player</title>
        </Helmet>
        <header className='Aetna-player-app-header'>
          <div className='title-banner'>
            <h1 className='title'>
              Understanding the basics of Medicare and Medicare Advantage
            </h1>
          </div>
          <div className='preview-player'>
            <Embed partD={false} />
          </div>
          <div className='alt-video'>
            <Link
              to='/embed'
              style={{
                margin: '2rem 0 0 0',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              Watch video that includes Medicare Part D
              <IoChevronForward
                style={{
                  color: '#7d3f98',
                  fontSize: '1.25em',
                  marginBottom: '0.25rem',
                  marginLeft: '0.5rem'
                }}
              />
            </Link>
            <br />
            <CopyEmbedForm partD={false} />
          </div>
        </header>
      </div>
    </>
  )
}

const EmbedPageV2 = () => {
  return (
    <>
      <div className='Aetna-Player-App'>
        <Helmet>
          <title>Medicare and Medicare Advantage - Video Player</title>
        </Helmet>
        <header className='Aetna-player-app-header'>
          <div className='title-banner'>
            <h1 className='title'>
              Understanding the basics of Medicare and Medicare Advantage
            </h1>
          </div>

          <div className='preview-player'>
            <Embed />
          </div>

          {/* <Link to={embedLink}>Embed</Link> */}
          <div className='alt-video'>
            <Link
              to='/embed2'
              style={{
                margin: '2rem 0 0 0',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              Watch video that excludes Medicare Part D{' '}
              <IoChevronForward
                style={{
                  color: '#7d3f98',
                  fontSize: '1.25em',
                  marginBottom: '0.25rem',
                  marginLeft: '0.5rem'
                }}
              />
            </Link>

            <br />
            <CopyEmbedForm />
          </div>
        </header>
      </div>
    </>
  )
}

export default App
