import { useState } from 'react'

const CopyEmbedForm = props => {
  const { partD = true } = props
  const [divCopied, setDivCopied] = useState(false)
  const [elementsCopied, setElementsCopied] = useState(false)
  const [scriptCopied, setScriptCopied] = useState(false)
  const [linkCopied, setLinkCopied] = useState(false)

  const copyText = (text, setState) => {
    if (text) navigator.clipboard.writeText(text)
    if (setState) {
      setState(true)
      setTimeout(() => setState(false), 1000)
    }
  }
  let embed = {
    div: '<div id="aetna-medicare-101-player"></div>',
    element:
      '<div id="aetna-medicare-101-player"><script src="https://aetnamedicarebasics.com/player/src/index.js" ></script><link rel="stylesheet" href="https://aetnamedicarebasics.com/player/src/index.css"/></div>',
    link:
      '<link href="https://aetnamedicarebasics.com/player/src/index.css" rel="stylesheet" />',
    script:
      '<script src="https://aetnamedicarebasics.com/player/src/index.js"></script>'
  }
  if (partD === false) {
    embed.div = '<div id="aetna-medicare-101-player-v2"></div>'
    embed.element =
      '<div id="aetna-medicare-101-player-v2"><script src="https://aetnamedicarebasics.com/player/src/index.js" ></script><link rel="stylesheet" href="https://aetnamedicarebasics.com/player/src/index.css"/></div>'
    embed.link =
      '<link href="https://aetnamedicarebasics.com/player/src/index.css" rel="stylesheet" />'
    embed.script =
      '<script src="https://aetnamedicarebasics.com/player/src/index.js"></script>'
  }

  return (
    <>
      <div className='copy-embed-form'>
        <div className='copy-embed-form-top'>
          <h2>How to embed player</h2>
          <hr />
          <p>
            In order to embed the interactive video player, copy the div, link,
            and script elements below and paste them all into your HTML page.
            Where you place the div determines where the video will be embedded.
            The video will scale to the size of the embed's container.
          </p>
        </div>

        <form id='copyEmbed' style={{}}>
          <label htmlFor='copyElements'>Click to copy player elements</label>
          <textarea
            id='copyElements'
            onClick={() => copyText(embed.element, setElementsCopied)}
            defaultValue={embed.element}
          />
          <p className={`copied-embed ${elementsCopied ? 'active' : ''}`}>
            copied elements
          </p>

          {/* <label htmlFor='copyLink'>Click to copy link</label>
          <textarea
            id='copyLink'
            onClick={() => copyText(embed.link, setLinkCopied)}
          >
            {embed.link}
          </textarea>
          <p className={`copied-embed ${linkCopied ? 'active' : ''}`}>
            copied link
          </p>

          <label htmlFor='copyScript'>Click to copy script</label>
          <textarea
            id='copyScript'
            onClick={() => copyText(embed.script, setScriptCopied)}
          >
            {embed.script}
          </textarea>
          <p className={`copied-embed ${scriptCopied ? 'active' : ''}`}>
            copied script
          </p> */}
        </form>
      </div>
    </>
  )
}

export default CopyEmbedForm
