import { useState, useEffect, useRef } from 'react'
import { AiOutlineMenu } from 'react-icons/ai'
import { AiOutlineClose } from 'react-icons/ai'

import { getCurrentChapter } from '../Controls/handlerFunctions'

import { usePlayer, useTimeoutTimelineEvent } from '../Media/Media'

import {
  controlsMouseOver,
  controlsMouseOut
} from '../Controls/handlerFunctions'

export default function TableOfContents (props) {
  const {
    chapterList,
    currentChapter,
    setCurrentChapter,
    heading = 'Chapter select',
    isTableOpen,
    setIsTableOpen,
    playerView,
    videoHover,
    chapterSelectDemo
  } = props

  const [firstRender, setFirstRender] = useState(true)
  const [lastChapterBlur, setLastChapterBlur] = useState(false)
  const [reverseLastChapterBlur, setReverseLastChapterBlur] = useState(false)
  const [tocBlur, setTocBlur] = useState(false)
  const lastChapterRef = useRef(null)
  const tocButtonRef = useRef(null)

  const player = usePlayer()
  useTimeoutTimelineEvent(chapterSelectDemo)

  useEffect(() => {
    if (tocButtonRef.current && lastChapterBlur === true)
      tocButtonRef.current.focus()
  }, [lastChapterBlur])

  useEffect(() => {
    if (lastChapterRef.current && tocBlur === true)
      lastChapterRef.current.focus()
  }, [tocBlur])

  useEffect(() => {
    if (isTableOpen) setFirstRender(false)
    if (!isTableOpen && !firstRender) tocButtonRef.current.focus()
  }, [isTableOpen])

  const handleKeyDown = (event, blurState, openState) => {
    switch (event.key) {
      case 'Tab':
        if (event.shiftKey) blurState(true)
        break
      case 'Esc':
      case 'Escape':
        openState(false)
        break
      case 'ArrowUp':
        break
      case 'ArrowDown':
        break
      default:
    }
  }

  const renderChapters = chapters =>
    chapters.map((chapter, index) => {
      const lastIndex = chapters.length - 1

      return (
        <li
          key={chapter.text + chapter.startTime}
          className={!!chapterSelectDemo ? 'wave' : ''}
          role='menuitem'
        >
          <button
            ref={lastIndex === index ? lastChapterRef : null}
            onClick={() => {
              setIsTableOpen(false)
              getCurrentChapter(
                chapter,
                player.ref,
                currentChapter,
                setCurrentChapter
              )
            }}
            onFocus={() => setLastChapterBlur(false) && setTocBlur(false)}
            onBlur={() =>
              lastIndex === index && reverseLastChapterBlur !== true
                ? setLastChapterBlur(true) && setTocBlur(false)
                : setLastChapterBlur(false) && setTocBlur(false)
            }
            tabIndex={isTableOpen === true ? 0 : -1}
            onKeyDown={event => {
              handleKeyDown(event, setReverseLastChapterBlur, setIsTableOpen)
            }}
          >
            <span className='index'>{index + 1}</span>
            <span>{chapter.text}</span>
          </button>
        </li>
      )
    })

  const handleMouseOver = () => controlsMouseOver(player)
  const handleMouseOut = () => controlsMouseOut(player)
  return (
    <>
      <div
        className={`ToC-container${isTableOpen === true ? ' active' : ''}${
          playerView === 'expanded' ? ' expanded' : ''
        }`}
      >
        {playerView === 'default' ? (
          <>
            <button
              ref={tocButtonRef}
              id='ToCButton'
              aria-label='Chapter select menu'
              aria-controls='tableOfContents'
              aria-expanded={isTableOpen === true ? 'true' : 'false'}
              className={`toc-icon${!!chapterSelectDemo ? ' wave' : ''}${
                videoHover === true ? ' hovered' : ''
              } ${isTableOpen === true ? ' active' : ''}`}
              onClick={() => setIsTableOpen(!isTableOpen)}
              onKeyDown={event =>
                handleKeyDown(event, setTocBlur, setIsTableOpen)
              }
              onFocus={handleMouseOver}
              onBlur={handleMouseOut}
            >
              {isTableOpen === true && !chapterSelectDemo ? (
                <AiOutlineClose
                  aria-hidden='true'
                  color='#fff'
                  style={{ width: '0.8rem', height: '0.8rem' }}
                />
              ) : (
                <AiOutlineMenu
                  aria-hidden='true'
                  color='#fff'
                  style={{ width: '0.8rem', height: '0.8rem' }}
                />
              )}
            </button>

            <svg
              aria-hidden='true'
              data-name='Layer 1'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 79.7 16.9'
              alt=''
            ></svg>
            <svg
              aria-hidden='true'
              className={'arrow' + (!!chapterSelectDemo ? ' shake' : '')}
              style={{ position: 'absolute', left: 90, top: 34 }}
              width='100px'
              fill='rgba(125, 63, 152, 1)'
              xmlns='http://www.w3.org/2000/svg'
              data-name='Layer 1'
              viewBox='0 0 79.7 16.9'
              x='0px'
              y='0px'
              alt=''
            >
              <path
                fill='#7d3f98'
                d='M9.7 11h70V6h-70l6-6H8.5L0 8.5l8.5 8.4h7.2l-6-6z'
              />
            </svg>
          </>
        ) : (
          ''
        )}
        <div
          id='tableOfContents'
          className={`ToC${!!chapterSelectDemo ? ' wave' : ''}${
            isTableOpen === true ? ' active' : ''
          }`}
          aria-hidden={isTableOpen === true ? 'false' : 'true'}
        >
          <nav aria-labelledby='ToCHeading'>
            <h2 id='ToCHeading'>{heading}</h2>
            <ol role='menu'>{renderChapters(chapterList)}</ol>
          </nav>
        </div>
      </div>
    </>
  )
}
