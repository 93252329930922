import {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useEffect,
  useRef
} from 'react'

import { createContainer } from 'react-tracked'

export const PlayerContext = createContext(null)

export const usePauseEffect = value => {
  const player = usePlayer()
  useEffect(() => {
    value && player.dispatch({ type: 'STOP' })
  }, [value])
}

export const usePlayer = () => {
  const context = useContext(PlayerContext)

  if (!context) {
    throw new Error(
      `The \`usePlayer\` hook must be used inside the <PlayerContext> component's context.`
    )
  }

  return context
}

let timelineTimeout = {}

export const useTimeoutTimelineEvent = (event, props = {}) => {
  const { shouldPlay = true } = props

  const player = usePlayer()

  useEffect(() => {
    if (event) {
      clearTimeout(timelineTimeout[event.id])
      timelineTimeout[event.id] = setTimeout(() => {
        player.dispatch({
          type: 'REMOVE_TIMELINE_EVENT',
          event: event,
          playing: shouldPlay
        })
      }, event.props.duration * 1000)

      return () => clearTimeout(timelineTimeout[event.id])
    }
  }, [event])
}

const playerReducer = (state, action) => {
  switch (action.type) {
    // mod-components candiates
    case 'ADD_TRIGGERED_EVENTS':
      return {
        ...state,
        timelineEvents: [...state.timelineEvents, ...action.events]
      }
    case 'ADD_PERSISTENT_EVENTS':
      return {
        ...state,
        persistentEvents: action.persistentEvents
      }
    case 'BUFFERING': {
      return {
        ...state,
        buffering: true
      }
    }
    case 'CLEAR_BUFFERING': {
      return {
        ...state,
        buffering: false
      }
    }
    case 'REMOVE_TIMELINE_EVENT':
      return {
        ...state,
        timelineEvents: state.timelineEvents.filter(
          event => event.id !== action.event.id
        ),
        playing: !!action.playing
      }
    case 'CLEAR_TIMELINE_EVENTS':
      return {
        ...state,
        timelineEvents: []
      }
    case 'PLAY':
      return {
        ...state,
        playing: true
      }
    case 'STOP':
      return {
        ...state,
        playing: false
      }
    case 'TOGGLE_PLAY':
      return {
        ...state,
        playing: !state.playing
      }
    case 'VOLUME':
      return {
        ...state,
        volume: action.volume
      }
    case 'MUTED':
      return {
        ...state,
        muted: action.muted
      }
    case 'PLAYED':
      return {
        ...state,
        played: action.played
      }
    case 'LOADED':
      return {
        ...state,
        loaded: action.loaded
      }
    case 'PLAYED_SECONDS':
      return {
        ...state,
        played: action.playedSeconds,
        currentChapter:
          action.playedSeconds > state.currentChapter.endTime ||
          action.playedSeconds < state.currentChapter.startTime
            ? state.chapterList.find(
                chapter =>
                  chapter.startTime < action.playedSeconds &&
                  chapter.endTime > action.playedSeconds
              ) || state.currentChapter
            : state.currentChapter
      }
    case 'ACTIVE_SECONDS':
      return {
        ...state,
        activeSeconds: action.activeSeconds
      }
    case 'DURATION':
      return {
        ...state,
        duration: action.duration
      }
    case 'PROGRESS':
      return {
        ...state,
        previousPlayedSeconds: state.playedSeconds,
        ...action.progress,
        currentChapter:
          action.progress.playedSeconds > state.currentChapter.endTime ||
          action.progress.playedSeconds < state.currentChapter.startTime
            ? state.chapterList.find(
                chapter =>
                  chapter.startTime < action.progress.playedSeconds &&
                  chapter.endTime > action.progress.playedSeconds
              ) || state.currentChapter
            : state.currentChapter
      }
    case 'SHOW_UI':
      return {
        ...state,
        uiVisible: true
      }
    case 'HIDE_UI':
      return {
        ...state,
        uiVisible: false
      }
    case 'CONTROLS_MOUSEOVER':
      return {
        ...state,
        controlsHovered: true
      }
    case 'CONTROLS_MOUSEOUT':
      return {
        ...state,
        controlsHovered: false
      }
    case 'CURRENT_CHAPTER':
      return {
        ...state,
        currentChapter: action.chapter
      }
    case 'CHAPTER_LIST':
      return {
        ...state,
        chapterList: action.chapterList,
        currentChapter: action.chapterList[0]
      }
    case 'CAPTION_FILES':
      return {
        ...state,
        captionFiles: action.captionFiles
      }

    // custom events for aetna-video-player
    case 'CLOSE_QUIZ':
      return {
        ...state,
        timelineEvents: state.timelineEvents.filter(
          event => event.type !== 'quiz'
        ),
        playing: true
      }
    default:
      return state
  }
}

export const usePlayerReducer = () =>
  useReducer(playerReducer, {
    buffering: false,
    timelineEvents: [],
    persistentEvents: [],
    volume: 1,
    playing: false,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 12 * 60 + 7,
    activeSeconds: null,
    playedSeconds: 0,
    loadedSeconds: 0,
    uiVisible: false,
    chapterList: [],
    captionFiles: []
  })

const { Provider: StateProvider, useTrackedState, useUpdate } = createContainer(
  usePlayerReducer
)

export const usePlayerState = useTrackedState

const PlayerProvider = props => {
  const dispatch = useUpdate()
  const state = useTrackedState()
  const ref = useRef()
  const context = useRef({
    ref,
    dispatch,
    state
  })

  const player = useMemo(() => {
    context.current.state = state
    return context.current
  }, [state])

  return (
    <PlayerContext.Provider value={player}>
      {props.children}
    </PlayerContext.Provider>
  )
}

export const Media = props => {
  const { children } = props

  return (
    <StateProvider>
      <PlayerProvider>{children}</PlayerProvider>
    </StateProvider>
  )
}
