import React, { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'

import { AiOutlinePlus } from 'react-icons/ai'
import { AiOutlineMinus } from 'react-icons/ai'
import { IoIosArrowForward } from 'react-icons/io'
import { FaExternalLinkAlt } from 'react-icons/fa'

export default function DisclaimerAccordion (props) {
  const {
    title = 'Disclaimers',
    isBorder,
    body = (
      <>
        <p class='p-body-xs white'>
          See Evidence of Coverage for a complete description of plan benefits,
          exclusions, limitations and conditions of coverage. Plan features and
          availability may vary by service area. The formulary, provider and/or
          pharmacy network may change at any time. You will receive notice when
          necessary. Out-of-network/non-contracted providers are under no
          obligation to treat Aetna members, except in emergency situations.
          Please call our customer service number or see your Evidence of
          Coverage for more information, including the cost-sharing that applies
          to out-of-network services. Participating physicians, hospitals and
          other health care providers are independent contractors and are
          neither agents nor employees of Aetna. The availability of any
          particular provider cannot be guaranteed, and provider network
          composition is subject to change.
        </p>

        <p class='white'>
          ©2021 Aetna Inc.
          <br />
          Y0001_GRP_3905b_2022_C
          <br />
          72.27.471.1 (09/21)
        </p>
      </>
    )
  } = props

  const [isAccordionOpen, setIsAccordionOpen] = useState(false)

  return (
    <div
      aria-labelledby={`accordionTitle${title}`}
      id={`accordion${title}`}
      className={`accordion ${isBorder ? 'border' : ''}`}
    >
      <button
        className={`btn${isAccordionOpen ? ' active' : ''}`}
        onClick={() => setIsAccordionOpen(!isAccordionOpen)}
        aria-expanded={isAccordionOpen ? 'true' : 'false'}
        aria-controls='transcriptContent'
      >
        <span aria-hidden='true' className='accordion-icon'>
          {isAccordionOpen ? (
            <AiOutlineMinus alt='' aria-hidden='true' />
          ) : (
            <AiOutlinePlus alt='' aria-hidden='true' />
          )}
        </span>
        <h2 id={`accordionTitle${title}`}>{title}</h2>
      </button>
      <div
        className={`accordion-content${isAccordionOpen ? ' active' : ''}`}
        aria-hidden={isAccordionOpen ? 'false' : 'true'}
        id='accordionContent'
      >
        {body}
      </div>
    </div>
  )
}
