import React, { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Transcripts from '../Transcripts/Transcripts'

import { AiOutlinePlus } from 'react-icons/ai'
import { AiOutlineMinus } from 'react-icons/ai'
import { IoIosArrowForward } from 'react-icons/io'

export default function TranscriptAccordion (props) {
  const {
    captionFiles,
    setCaptionFiles,
    cues,
    setCues,
    title = 'Read transcript',
    childRef,
    currentCaptions,
    isTranscriptOpen,
    setIsTranscriptOpen
  } = props

  const [isAccordionOpen, setIsAccordionOpen] = useState(false)

  return (
    <div
      aria-labelledby={`transcriptAccordionTitle`}
      id={`accordion${title}`}
      className='accordion'
    >
      <button
        className={`btn${isAccordionOpen ? ' active' : ''}`}
        onClick={() => setIsAccordionOpen(!isAccordionOpen)}
        aria-expanded={isAccordionOpen ? 'true' : 'false'}
        aria-controls='transcriptContent'
      >
        <span aria-hidden='true' className='accordion-icon'>
          {isAccordionOpen ? (
            <AiOutlineMinus alt='' aria-hidden='true' />
          ) : (
            <AiOutlinePlus alt='' aria-hidden='true' />
          )}
        </span>
        <h2 id={`transcriptAccordionTitle`}>{title}</h2>
      </button>
      <div
        className={`accordion-content${isAccordionOpen ? ' active' : ''}`}
        aria-hidden={isAccordionOpen ? 'false' : 'true'}
        id='transcriptContent'
      >
        <Transcripts
          accordion
          captionFiles={captionFiles}
          setCaptionFiles={setCaptionFiles}
          cues={cues}
          setCues={setCues}
          childRef={childRef}
          currentCaptions={currentCaptions}
          isTranscriptOpen={isTranscriptOpen}
          setIsTranscriptOpen={setIsTranscriptOpen}
        />
      </div>
    </div>
  )
}
