export default function Navbar (props) {
  return (
    <div id='aetna-medicare-101-navbar'>
      <div className='navbar-content-container'>
        <div className='aetna-logo-container'>
          <img
            src='Aetna_Logo.svg'
            alt='Aetna logo'
            height='auto'
            width={140}
          />
        </div>
      </div>
    </div>
  )
}
