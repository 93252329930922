import { FaExternalLinkAlt } from 'react-icons/fa'
import DisclaimerAccordion from './DisclaimerAccordion'

export default function Footer (props) {
  const {
    mainLinks,
    secondaryLinks = [
      {
        url:
          'https://www.aetnamedicare.com/en/footers/privacy-information-footer.html',
        body: 'Privacy center',
        target: '_blank'
      },
      {
        url: 'https://www.aetnamedicare.com/en/footers/fraud-waste-abuse.html',
        body: 'Fraud & Abuse',
        target: '_blank'
      },
      {
        url: 'https://www.aetnamedicare.com/en/footers/terms-of-use.html',
        body: 'Terms of use',
        target: '_blank'
      },
      {
        url:
          'https://www.aetnamedicare.com/en/footers/interest-based-advertisements-policy.html',
        body: 'Internet-based ads policy',
        target: '_blank'
      }
    ]
  } = props

  const renderLinks = links =>
    links.map(link => {
      return (
        <li>
          <a
            href={link.url}
            target={link.target ? link.target : '_self'}
            rel={link.target ? 'noopener' : ''}
          >
            {link.body}
            {link.target === '_blank' ? (
              <>
                {' '}
                <FaExternalLinkAlt aria-hidden='true' alt='' />
                &nbsp;
                <span class='sr-only'> opens in a new tab</span>{' '}
              </>
            ) : (
              ''
            )}
          </a>
        </li>
      )
    })

  return (
    <footer class='wrapper' id='aetna-medicare-101-wrapper-footer'>
      <div class='row upper-footer'>
        <div class='col-12'>
          <div class={`site-footer ${!mainLinks ? 'no-border' : ''}`}>
            <div>
              <p class='p-body-xs white initial-disclaimer'>
                Aetna Medicare is a HMO, PPO plan with a Medicare contract.
                Enrollment in our plans depends on contract renewal.
              </p>
              <div class='expandCollapse'>
                <div>
                  <DisclaimerAccordion />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav aria-label='footer'>
        {mainLinks && (
          <div class='row middle-footer'>
            <div class='col-12 col-md-6 col-lg-8 site-map'>
              <div class='row'>
                <ul class='col-12 col-lg-5 d-lg-flex flex-lg-column  flex-wrap'>
                  {renderLinks(mainLinks)}
                </ul>
              </div>
            </div>
          </div>
        )}
        {secondaryLinks && (
          <div class='row lower-footer d-lg-flex justify-content-lg-between'>
            <div class='col-12 col-lg-10 external-links'>
              <ul class='d-lg-flex align-items-center'>
                {renderLinks(secondaryLinks)}
              </ul>
            </div>
          </div>
        )}
      </nav>
    </footer>
  )
}
