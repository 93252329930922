import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import Embed from './components/Embed/Embed'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
  apiKey: 'ac28e80a5bf6e5c0f2344c06e1aadae4',
  plugins: [new BugsnagPluginReact()]
})


window.React = React

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

if (document.getElementById('aetna-medicare-101-preview'))
  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('aetna-medicare-101-preview')
  )

if (document.getElementById('aetna-medicare-101-player'))
  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary>
        <Embed />
      </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('aetna-medicare-101-player')
  )

if (document.getElementById('aetna-medicare-101-player-v2'))
  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary>
        <Embed partD={false} />
      </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('aetna-medicare-101-player-v2')
  )
