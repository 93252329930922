import { useRef, useEffect, memo } from 'react'
import { usePlayerState } from '../Media/Media'

const AudioTrack = props => {
  const { loop, src } = props
  const ref = useRef()

  const { volume, muted } = usePlayerState()

  useEffect(() => {
    ref.current.volume = volume
  }, [volume])

  const handleCanPlay = () => ref.current.play()

  return (
    <audio ref={ref} onCanPlay={handleCanPlay} loop={!!loop} muted={muted}>
      <source src={src} type='audio/mp3' />
    </audio>
  )
}

export default memo(AudioTrack)
